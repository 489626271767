.create-an-account-popup{
    .card {

        border: none;
        width: 496px;

        box-shadow: 0px 5px 20px 0px #d2dae3;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .card h6 {
        color: var(--grey-grey-1, #0D0D0D);
        font-size: 32px;
        font-weight: 600;
        line-height: 39.4px;

        text-transform: capitalize;
    }

    .card-2 {
        background-color: #fff;
        padding: 10px;
        width: 350px;
        height: 100px;
        bottom: -50px;
        left: 20px;
        position: absolute;
        border-radius: 5px
    }

    .card-left {
        margin: 45px;
    }

    .modal-body {
        padding: 0px !important;
    }

    .card-2 .content {
        margin-top: 50px
    }

    .card-2 .content a {
        color: red
    }


    // .form-control:focus {
    //     box-shadow: none;
    //     border: 2px solid red
    // }

    .validate {

        height: 40px;
        text-align: center;
        border-radius: 40px;
        background-color: #1B375C;
        border: 1px solid #1B375C;
        width: 140px
    }

    .otp-btn {
        text-align: center;
    }

    .resend {
        // margin-left: 88px !important;
        text-decoration: none;
    }

    .inputs input {
        width: 40px;
        height: 40px;
    }

    .para {
        color: var(--grey-grey-2, #818181);
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-transform: capitalize;
    }
}