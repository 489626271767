.rec-after-login-sec {
    text-align: center;

    // =================profile circle start==========================
    .profile-image-parent {
        .box .content .percent {
            position: relative;
            width: 150px;
            height: 150px;
            margin: 0 auto;
        }

        .box .content {
            .circle-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 85%;
            }
        }

        // .box .content .percent::before {
        //     content: attr(data-text);
        //     position: absolute;
        //     inset: 20px;
        //     background: #555;
        //     border-radius: 50%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: #fff;
        //     font-size: 1.75rem;
        //     letter-spacing: 0.1rem;
        //     text-transform: uppercase;
        // }

        .box .content .percent svg {
            position: relative;
            width: 150px;
            height: 150px;
            transform: rotate(270deg);
        }

        .box .content .percent svg circle {
            width: 100%;
            height: 100%;
            fill: transparent;
            stroke-width: 3;
            stroke: rgba(0, 0, 0, 0.05);
            transform: translate(5px, 5px);
        }

        .box .content .percent svg circle:nth-child(2) {
            stroke: rgba(10, 101, 204, 1);

            stroke-dasharray: 440;
            // stroke-dashoffset: calc(440 - (440 * 50) / 100);
            opacity: 0;
            animation: fadeIn 1s linear forwards;
            animation-delay: 2.5s;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .box .content .percent .dot {
            position: absolute;
            inset: 5px;
            z-index: 10;

            // animation: animateDot 2s linear forwards;
            // transform: rotate(calc(3.6deg * 80));
            .profile-com-number {
                position: absolute;
                top: -4px;
                left: 56px;
                color: #fff;
                font-size: 13px;
                font-weight: 700;
            }
        }

        @keyframes animateDot {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(calc(3.6deg * 80));
            }
        }

        .box .content .percent .dot::before {
            content: "";
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            background: rgba(10, 101, 204, 1);
            border-radius: 50%;
        }

        // .box .content .number {
        //     position: relative;
        //     inset: 0;
        //     opacity: 0;
        //     animation: fadeIn 1s linear forwards;
        //     animation-delay: 2.5s;
        // }

        // .box .content .number h2 {
        //     font-size: 2.5rem;
        //     color: #555;
        // }

        // .box .content .number h2 span {
        //     font-weight: 300;
        //     font-size: 1.5rem;
        // }

    }

    // =======================profile circle end=============================
    .main-header {
        background: #1B375C;
        padding: 10px 0;
        display: flex;
        align-items: center;

        .head-sec-lft {
            display: flex;
            align-items: center;

            i {
                color: #fff;
            }

            p {
                color: #fff;
                margin-bottom: unset;
            }

            .translator {
                ul {
                    margin-bottom: unset;
                    list-style: none;
                    padding-left: 0;
                    color: #fff;
                }
            }
        }

        .head-sec-rit {
            .search-top-area-dv {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;          

                .recent-search-dv {
                    color: #fff;
                    margin-right: 20px;
                }

                .recent-input-dv {
                    position: relative;
                    .recent-input {
                        padding: 10px 40px 10px 10px;
                        border-radius: 50px;
                        border: none;
                        outline: none;
                        
                    }
                    .recent-input-atag {
                        position: absolute;
                        top: 50%;
                        right: 0%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .head-right-user {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;

                .user-name-add {
                    text-align: right;

                    h3 {
                        font-size: 18px;
                        color: #fff;
                        font-weight: 700;
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    p {
                        color: #fff;
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                .user-img {
                    width: 60px;
                    height: 60px;
                    margin-left: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            display: flex;
            align-items: center;
            justify-content: end;

            .btn-lft button {
                background-color: unset;
                border: 1px solid #fff;
                border-radius: 50px;
                padding: 5px 20px;
            }

            .btn-rit button {
                background-color: #fff;
                color: #000;
                border-radius: 50px;
                padding: 5px 20px;
            }


            .dropdown-toggle {
                color: #fff;
            }

            .dropdown-menu li {
                display: flex;
                align-items: center;
                padding: 5px;
            }
        }
    }

    .header-second {
        background: #FFF;
        // height: 80px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

        .menu-tab {
            margin-right: 20px;
        }

        nav {
            background-color: #fff;
            z-index: 99;
        }

        .navbar-nav li {
            margin: 0 4px;

            .after-login-nav-icon {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;
            }

            a {
                color: #000;
                font-size: 15px;
                font-weight: 600;
                padding: 0 4px;
            }

            .rec-drop-down-ul {
                li {
                    a {
                        padding: 8px 10px;
                    }
                }
            }
        }

        .navbar-brand {
            color: #1B375C;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 100% */
            text-transform: uppercase;
        }

        .header-sec-btn1 button {
            background-color: unset;
            border: 1px solid #0A65CC;
            color: #0A65CC;
            border-radius: 50px;
            padding: 8px 10px;
        }

        .header-sec-btn2 button {
            background-color: #0A65CC;
            color: #fff;
            border-radius: 50px;
            padding: 8px 20px;
        }

        .header-sec-btn2 {
            a {
                background: rgba(10, 101, 204, 1);
                width: 54px;
                height: 54px;
                border-radius: 8px;
                line-height: 35px;
            }
        }

        .navbar-ul-custom {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;

            .nav-item-custom-space {
                margin: auto 6rem;
            }
        }

    }

    .sidebar-menu-parent {

        .sidebar-menu {
            height: 100%;
            width: 320px;
            background-color: #fff;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 10;
            transform: translateX(-130%);
            transition: 0.5s ease-in-out;

            .sidebar-link {
                height: 450px;
                overflow-y: scroll;

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    background-color: #F5F5F5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #1B375C;
                    background-image: -webkit-linear-gradient(45deg,
                            rgba(255, 255, 255, .2) 25%,
                            transparent 25%,
                            transparent 50%,
                            rgba(255, 255, 255, .2) 50%,
                            rgba(255, 255, 255, .2) 75%,
                            transparent 75%,
                            transparent)
                }
            }

            .sidebar-menu-btn {
                width: 60px;
                background-color: #fff;
                position: absolute;
                top: 0;
                right: -60px;
                cursor: pointer;

                p {
                    margin-bottom: 0px;
                    font-size: 16px;
                }
            }

            .sidebar-unlist {
                list-style: none;

                li {
                    img {
                        width: 28px;
                    }

                    text-align: left;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 25px 0px;

                    &:last-child {
                        margin-bottom: 80px;
                    }

                    span {
                        margin-left: 26px;

                        a {
                            text-decoration: none;
                            color: inherit;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .profile-images {
                margin-top: 30px;

                img {
                    width: 110px;
                    // height: 110px;
                    border-radius: 10px;
                }
            }

            .user-name {
                h4 {
                    color: #000;
                    font-size: 24px;
                    margin-top: 20px;
                }
            }
        }

        .sidebar-menu-after::after {
            content: "";
            background-color: #00000070;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 9;

        }

        .sidebar-menu-toggle {
            height: 100%;
            width: 320px;
            background-color: #fff;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 10;
            transform: translateX(0%) !important;
            transition: 0.5ss ease-in-out;
            z-index: 999;
        }

    }

    .menu-tab-btn {
        cursor: pointer;
        padding: 15px 5px;
    }

    .modal-body-content {
        padding: 30px 40px;

        .modal-body-btn {
            margin-top: 30px;

            .modal-body-content-body {
                line-height: 40px;
            }

            .logout-cancel-btn {
                width: 40%;
                height: 45px;
                top: 317px;
                left: 108px;
                border-radius: 40px;
                line-height: 45px;
                border: 1px solid rgba(27, 55, 92, 1);
                margin: 0px 10px;
            }

            .logout-btn {
                width: 40%;
                height: 45px;
                top: 317px;
                left: 108px;
                border-radius: 40px;
                line-height: 45px;
                background: rgba(27, 55, 92, 1);
                border: none;
                outline: none;
                color: #fff;
                margin: 0px 10px;
            }
        }
    }
}