.after-login-dash {
  padding: 50px 0;
}
.after-login-dash .profile-head {
  position: relative;
  padding: unset;
}
.after-login-dash .profile-head .edit-prfile {
  position: absolute;
  top: 15px;
  right: 61px;
  image-rendering: auto;
}
.after-login-dash .profile-head .edit-prfile img {
  margin-bottom: 5px;
  padding: 0 5px 0 0;
}
.after-login-dash .profile-head .edit-prfile button {
  border-radius: 4px;
  background: #FE6108;
  border: unset;
  padding: 8px 20px;
  box-shadow: 0px 10px 30px 0px rgba(113, 123, 133, 0.05);
  color: #FFF5F5;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.after-login-dash .profile-head .dots {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 4px;
  padding: 6px 5px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 10px 30px 0px rgba(113, 123, 133, 0.05);
}
.after-login-dash .profile-info {
  position: relative;
}
.after-login-dash .profile-info ul li {
  list-style: none;
  text-align: start;
  line-height: 30px;
  color: #181818;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
}
.after-login-dash .profile-info .profile-img {
  position: absolute;
  top: -25px;
}
.after-login-dash .profile-info .pro-complete {
  background: #1B375C;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.after-login-dash .profile-info .pro-complete .sec-lft h3 {
  color: #FFF;
  text-align: center;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
  line-height: 30.4px;
  text-transform: capitalize;
}
.after-login-dash .profile-info .pro-complete .sec-lft p {
  margin: unset;
  color: #fff;
  padding: 5px 0 0 8px;
}
.after-login-dash .profile-info .pro-complete .sec-rit img {
  padding: 12px 0;
}
.after-login-dash .profile-info .pro-complete .sec-rit .mission-btn button {
  padding: 5px 10px;
  color: #1B375C;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  border-radius: 40px;
  border: unset;
}
.after-login-dash .performance .rec-actn {
  padding: 50px 20px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.after-login-dash .performance .head + .col-lg-6 {
  border-right: 1px dashed #1B375C;
}
.after-login-dash .performance h4 {
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 30px;
  padding-top: 10px;
}
.after-login-dash .performance h5 {
  color: #000;
  font-family: Barlow;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 15px;
}
.after-login-dash .performance h2 {
  text-align: left;
  color: #1B375C;
  font-family: Barlow;
  font-size: 24px;
  font-weight: 700;
  margin-left: 7px;
}
.after-login-dash .resume .custm-resume {
  padding: 20px 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.after-login-dash .resume h3 {
  text-align: left;
  color: #1B375C;
  font-size: 17px;
  font-weight: 500;
}
.after-login-dash .resume p {
  text-align: left;
  color: var(--gray-500, #71717A);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.after-login-dash .apk .custm-apk {
  padding: 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.after-login-dash .apk .custm-apk .custm-qr {
  width: 130px;
  height: 130px;
}
.after-login-dash .apk .custm-apk .custm-download img {
  margin: 5px 0;
}
.after-login-dash .apk h4 {
  text-align: left;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-transform: capitalize;
}

.custm-jobs .custm-left-sec {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 30px 12px;
  margin-top: 30px;
}
.custm-jobs .desc {
  display: flex;
  margin: 20px 10px;
}
.custm-jobs .desc img {
  width: 30px;
  height: 30px;
}
.custm-jobs .desc .textarea h5,
.custm-jobs .desc .textarea h6 {
  padding: 0 15px;
  text-align: left;
}
.custm-jobs .desc .textarea h5 {
  color: #000;
  font-size: 17px;
  font-weight: 400;
}
.custm-jobs .desc .textarea h6 {
  color: #1B375C;
  font-size: 17px;
  font-weight: 400;
}
.custm-jobs .custm-right-sec {
  padding: 30px 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  margin-top: 30px;
}
.custm-jobs .custm-right-sec .head-area h3 {
  text-align: start;
  color: #1B375C;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
}
.custm-jobs .custm-right-sec .latest-job-card {
  border-radius: 7px;
  background: #1B375C;
  padding: 20px;
  margin: 0 30px 0 5px;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group {
  margin-bottom: 30px;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group h4 {
  color: #F8F8F8;
  font-size: 14px;
  font-weight: 400;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group .comp-logo {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group img {
  width: 55px;
  height: 25px;
  flex-shrink: 0;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group .job-role h5 {
  color: #FFF;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.custm-jobs .custm-right-sec .latest-job-card .company-group .job-role h6 {
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
}
.custm-jobs .custm-right-sec .latest-job-card .job-title {
  margin-top: 15px;
}
.custm-jobs .custm-right-sec .latest-job-card .job-title h5 {
  color: #FFF;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.custm-jobs .custm-right-sec .latest-job-card .job-title h6 {
  color: #F8F8F8;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.custm-jobs .custm-right-sec .latest-job-card .job-loc {
  margin-top: 20px;
}
.custm-jobs .custm-right-sec .latest-job-card .job-loc button {
  display: flex;
  align-items: center;
  width: 80px;
  height: 25px;
  border: unset;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  margin: 0 3px;
}
.custm-jobs .custm-right-sec .latest-job-card .job-loc button a {
  color: #FFF;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
}
.custm-jobs .custm-right-sec .latest-job-card-two {
  border-radius: 7px;
  background: #ffffff;
  padding: 20px;
  margin: 0 30px 0 5px;
  border-radius: 7px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group {
  margin-bottom: 30px;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group h4 {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group .comp-logo {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group img {
  width: 55px;
  height: 25px;
  flex-shrink: 0;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group .job-role h5 {
  color: #000000;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.custm-jobs .custm-right-sec .latest-job-card-two .company-group .job-role h6 {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-title {
  margin-top: 15px;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-title h5 {
  color: #000000;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-title h6 {
  color: #000000;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-loc {
  margin-top: 20px;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-loc button {
  display: flex;
  align-items: center;
  width: 80px;
  height: 25px;
  border: unset;
  border-radius: 5px;
  background: rgba(105, 105, 115, 0.1);
  padding: 5px 10px;
  margin: 0 3px;
}
.custm-jobs .custm-right-sec .latest-job-card-two .job-loc button a {
  color: #000000;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
}
.custm-jobs .custm-right-sec .show-btn button {
  border: unset;
  background-color: #fff;
  color: #0A65CC;
}
.custm-jobs .rating-head {
  padding: 30px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-jobs .rating-head h3 {
  padding: 20px 0;
  color: #1B375C;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
}
.custm-jobs .rating-head button {
  border-radius: 40px;
  background: #1B375C;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 45px;
  margin: 20px 0;
}
.custm-jobs .rating-head .rating_scale {
  display: block;
  text-align: center;
  font-size: 0;
}
.custm-jobs .rating-head .rating_scale span {
  display: inline-block;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #D9D9D9;
  color: #696973;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
}
.custm-jobs .rating-head .rating_scale label span {
  border-radius: 10px;
  margin: 5px;
}
.custm-jobs .rating-head .rating_scale label:last-child span {
  border-radius: 10px;
}
.custm-jobs .rating-head .rating_scale input[type=radio] {
  display: none;
}
.custm-jobs .rating-head .rating_scale input[type=radio]:checked ~ span {
  background: #1B375C;
  color: #fff;
}
.custm-jobs .recruiters {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-jobs .recruiters .left-sec {
  padding: 30px;
  border-right: 1px solid #D9D9D9;
}
.custm-jobs .recruiters .left-sec .lft-detail h4 {
  text-align: start;
  color: #1B375C;
  font-family: Barlow;
  font-size: 24px;
  font-weight: 700;
}
.custm-jobs .recruiters .right-sec {
  padding-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;
}
.custm-jobs .recruiters .right-sec:last-child {
  border-bottom: unset;
}
.custm-jobs .recruiters .right-sec img {
  width: 52px;
  height: 52px;
}
.custm-jobs .recruiters .right-sec .text-area h3 {
  font-size: 18px;
  color: var(--light-text-primary, #1C1C1E);
  font-size: 16px;
  font-weight: 500;
  text-align: start;
}
.custm-jobs .recruiters .right-sec .text-area .loc h4 {
  color: var(--light-text-primary, #1C1C1E);
  font-size: 12px;
  font-weight: 400;
}
.custm-jobs .recruiters .right-sec .text-area .loc h5 {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  font-size: 12px;
  font-weight: 400;
  margin: 0 8px;
}
.custm-jobs .famous-coumpany {
  padding: 30px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.custm-jobs .famous-coumpany .head-area h2 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 700;
}
.custm-jobs .famous-coumpany .head-area button {
  border: unset;
  background-color: rgba(255, 255, 255, 0);
  color: #0A65CC;
}
.custm-fam-comp {
  border-radius: 10px;
  border: 1px solid #FFF5FF;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px #EBEBF0;
  padding: 25px;
  height: 200px;
}
.custm-fam-comp h5 {
  color: var(--violet-700, #6D28D9);
  font-size: 12px;
  font-weight: 400;
}
.custm-fam-comp .custm-company-info h3 {
  color: var(--gray-700, #3F3F46);
  font-size: 14px;
  font-weight: 500;
  padding: 0 5px 0 0;
}
.custm-fam-comp .custm-company-info h4 {
  color: var(--gray-400, #A1A1AA);
  font-size: 12px;
  font-weight: 400;
}
.custm-fam-comp .comp-title h2 {
  text-align: left;
  color: var(--gray-900, #18181B);
  font-size: 18px;
  font-weight: 700;
}
.custm-fam-comp .comp-title p {
  text-align: left;
  color: var(--gray-500, #71717A);
  font-size: 14px;
  font-weight: 400;
}

.invite .wtsp-update {
  display: grid;
  padding: 25px 45px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.invite .wtsp-update img {
  padding: 0 0 10px 0;
}
.invite .wtsp-update h2 {
  text-align: left;
  color: #001833;
  font-size: 18px;
  font-weight: 700;
}
.invite .wtsp-update p {
  text-align: left;
  color: #001833;
  font-size: 14px;
  font-weight: 400;
}
.invite .custm-invite {
  padding: 25px 45px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.invite .custm-invite h2 {
  text-align: left;
  color: #001833;
  font-size: 18px;
  font-weight: 700;
}
.invite .custm-invite p {
  text-align: left;
  color: #001833;
  font-size: 14px;
  font-weight: 400;
}
.invite .custm-invite button {
  background-color: #0049fc;
  color: #fff;
  border: unset;
  border-radius: 10px;
  padding: 10px 20px;
}
.invite .custm-invite .share-txt + div img {
  height: 240px;
}/*# sourceMappingURL=AfterLoginProfile.css.map */