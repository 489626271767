.add-pay-benefits {
  margin: 40px 0px;
}
.add-pay-benefits .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.add-pay-benefits .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.add-pay-benefits .create-an-emp-inner .heading-inner h3 {
  font-size: 22px;
  font-weight: 600;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 40px;
  margin: 20px 0px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle {
  border: 1px solid rgb(27, 55, 92);
  color: #1B375C;
  border-radius: 40px;
  background-color: #fff;
  padding: 10px 0px;
  width: 100%;
  font-size: 15px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle:hover {
  background-color: #1B375C;
  color: #fff;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv label {
  font-weight: 600;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .inner-input .radio-inpt label {
  color: rgba(102, 102, 102, 0.93);
  font-weight: 400;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .inner-input select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .left-sd-content p {
  margin-bottom: 0px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 40px;
  padding: 10px 40px;
  text-decoration: none;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn span {
  margin-left: 10px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .supplement-pay-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .supplement-pay-btn .supplement-item {
  margin: 10px 15px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv .supplement-pay-btn .supplement-item .job-type-btn .btn-circle {
  padding: 10px 20px;
}
.add-pay-benefits .create-an-emp-inner .card-outer-dv1 {
  padding: 30px 40px;
}
.add-pay-benefits .create-an-emp-inner .bottom-button-dv {
  margin: 40px 0px;
}
.add-pay-benefits .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  color: linear-gradient(0deg, #1B375C, #1B375C);
  padding: 10px 50px;
}
.add-pay-benefits .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 50px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .add-pay-benefits .create-an-emp-inner {
    padding: 10px;
  }
  .add-pay-benefits .create-an-emp-inner .card-outer-dv {
    padding: 10px 20px;
  }
  .add-pay-benefits .create-an-emp-inner .card-outer-dv .right-sd-btn {
    margin: 30px 0px;
  }
  .add-pay-benefits .create-an-emp-inner .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .add-pay-benefits .create-an-emp-inner .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
}/*# sourceMappingURL=AddPayAndBenefits.css.map */