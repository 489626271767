@media only screen and (min-width:200px) and (max-width:567px) {

    // ===============Personal Detail===================
    .personal-detail {
        .form-area {
            .custm-btn-sec {
                display: block !important;
            }

            padding: 22px;

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .exp-input {
                    display: block !important;
                }


                .verify-btn {
                    padding: 6px 28px;
                }

                .cus-pass-section {
                    span {
                        top: 47px;
                    }
                }

                .cv-upload {
                    .upload {
                        position: relative;

                        .cv-upload-input-cus {
                            width: 100%;
                        }

                        img {
                            width: 30px;
                            height: 30px;
                            top: 0;
                            position: absolute;
                            right: 0;
                        }
                    }

                    .resume {
                        h4 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }

                }


                .cus-verify {
                    img {
                        display: none;
                    }
                }

                .profile-edit-btn {
                    .edit-btn {
                        a {
                            padding: 5px 40px;
                        }
                    }
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
    }

    // ===============Educational Detail===================
    .educational-detail {
        .form-area {
            padding: 22px;

            .edu-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .professional-detail {
        .form-area {
            padding: 22px;

            .edu-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .work-confo {
                    display: block !important;
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Professional Detail===================
    .project-detail {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Certification===================
    .certification {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    // ===============Social Median===================
    .social-media {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }


    // ===============Key Skills===================
    .key-skills {
        .form-area {
            padding: 22px;

            .project-title {
                h1 {
                    font-size: 20px;
                    padding: 10px 0;
                }
            }

            form {
                input {
                    padding: 8px !important;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                select {
                    padding: 8px !important;
                    font-size: 13px;

                    option {
                        font-size: 13px;
                    }
                }

                .custm-botton-btn {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .skip {
                    padding: 8px 35px;
                    font-size: 16px;
                }

                .next {
                    padding: 8px 35px;
                    font-size: 16px;
                }
            }

            .custm-btn-sec {
                display: block !important;
            }
        }
    }

    @media only screen and (min-width:200px) and (max-width:767px) {

        // ===============Personal Detail===================
        .form-area {
            .personal-detail {
                .cv-upload {
                    .resume {
                        h4 {
                            font-size: 14px !important;
                        }

                        p {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }

}