.stepper-section .cus-step {
  padding: 40px;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
}
.stepper-section .steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.stepper-section .step-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: #0A65CC;
  transition: 0.4s;
}
.stepper-section .step-button[aria-expanded=true] {
  width: 20px;
  height: 20px;
  background-color: #0A65CC;
  color: #fff;
}
.stepper-section .done {
  background-color: #0A65CC;
  color: #fff;
}
.stepper-section .step-item {
  z-index: 10;
  text-align: center;
}
.stepper-section #progress {
  position: absolute;
  width: 93%;
  z-index: 5;
  top: 8px;
  left: 40px;
  height: 7px;
  -webkit-appearance: unset;
     -moz-appearance: unset;
          appearance: unset;
  text-align: center;
}
.stepper-section #progress::-webkit-progress-value {
  background-color: #0A65CC;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.stepper-section #progress::-webkit-progress-bar {
  background-color: #ccc;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .stepper-section .step-button[aria-expanded=true] {
    width: 8px;
    height: 8px;
    background-color: #0A65CC;
    color: #fff;
  }
  .stepper-section .step-button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: none;
    background-color: #0A65CC;
    transition: 0.4s;
  }
  .stepper-section .step-item .step-title {
    font-size: 12px;
  }
  .stepper-section #progress {
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 12px;
    left: 5px;
    height: 3px;
    -webkit-appearance: unset;
       -moz-appearance: unset;
            appearance: unset;
    text-align: center;
  }
}/*# sourceMappingURL=Steppercss.css.map */