.learning-video-section {
    .main-subs-plan-lernvido {
        .cus-head {
            width: 100%;
            height: 65px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #F4FAFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

            h5 {
                color: #1B375C;
                font-family: Barlow;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                text-transform: capitalize;
                padding: 20px 12px 12px 12px;
            }
        }

        .subscrip-card {
            border-radius: 4px;
            padding: 20px 20px 30px 20px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .card.cus-card {
                border: unset;
            }

            /* Custom styles for the video */
            .card-video {

                video {
                    width: 100%;
                    border-radius: 10px;
                    background: lightgray 0px -97.989px / 100% 318.162% no-repeat;
                }

                .cus-sec-vid {
                    .a-tag-link{
                        text-decoration: none;
                        p {
                            color: #141522;
                            // font-family: Plus Jakarta Sans;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            letter-spacing: -0.32px;
                            margin-bottom: 0;
                        }
                    }
                    

                    span {
                        color: #54577A;
                        // font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.24px;
                    }
                }
            }

            .card-body.cus-b {
                padding: unset;

                .card-title {
                    color: #141522;

                    // font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    /* 24px */
                    letter-spacing: -0.32px;

                    //   margin-top: 1rem;

                }

                .cus-sec-2 {
                    span {
                        color: #141522;


                        // font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        /* 24px */
                        letter-spacing: -0.32px;
                    }
                }
            }


            /* Custom styles for the progress bar */
            .progress {
                margin-top: 0.5rem;

                .progress-bar {
                    width: 50%;
                    /* Customize the width as needed */
                }

                .cus-bar {
                    border-radius: 8px 0px 0px 8px !important;
                    background: #546FFF !important;
                }

            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:767px){
    .learning-video-section{
        .main-subs-plan-lernvido {
            .subscrip-card {
                border-radius: 4px;
                padding: 0px 20px 30px 20px;
                background: #FFF;
                box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
            }
        }
    }
}