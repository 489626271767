.reclogincontent-section .mt-custon-1 {
  margin-top: 5rem;
}
.reclogincontent-section .user-envlop-cls {
  position: relative;
}
.reclogincontent-section .user-envlop-cls span {
  position: absolute;
  left: 15px;
  top: 8px;
}
.reclogincontent-section .user-envlop-cls input {
  padding-left: 40px;
}
.reclogincontent-section .user-envlop-cls2 {
  position: relative;
}
.reclogincontent-section .user-envlop-cls2 .img {
  position: absolute;
  left: 27px;
  top: 12px;
}
.reclogincontent-section .user-envlop-cls2 input {
  padding-left: 40px;
}
.reclogincontent-section li {
  list-style: none;
  padding: 5px 10px 10px;
}
.reclogincontent-section a {
  text-decoration: none;
}
.reclogincontent-section .input-text {
  width: 100%;
  height: 44px;
  border: 1px solid #a59b9b;
  border-radius: 10px;
  padding: 10px;
}
.reclogincontent-section .pt-2 {
  color: #a59b9b;
}
.reclogincontent-section ul {
  display: flex;
  justify-content: center;
  margin-right: 121px;
}
.reclogincontent-section .para {
  margin-bottom: 0px;
  margin-top: 30px;
  color: #a59b9b;
  text-align: left;
}
.reclogincontent-section .row-top {
  margin-top: 15px;
}
.reclogincontent-section form {
  margin: 30px;
}
.reclogincontent-section .pt-3 {
  text-align: center;
  /* margin: 12px; */
  margin-right: 101px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  color: #a59b9b;
}
.reclogincontent-section .second-section {
  border-radius: 13px;
  margin-top: 80px;
  margin-bottom: 80px;
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.reclogincontent-section .para-3 {
  margin-bottom: 1px;
  margin-top: 22px;
}
.reclogincontent-section .password-custom {
  position: relative;
}
.reclogincontent-section .password-custom span {
  position: absolute;
  right: 25px;
  top: 14px;
  color: #939191;
}
.reclogincontent-section .login-btn a {
  border-radius: 30px;
  background: rgb(27, 55, 92);
  color: white;
  font-size: 17px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 145px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .reclogincontent-section .pt-2 {
    color: #a59b9b;
    text-align: left;
  }
  .reclogincontent-section .pt-2 a {
    color: #a59b9b;
  }
}/*# sourceMappingURL=RecLogin.css.map */