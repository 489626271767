.create-an-account-section {
    margin: 50px 0px;

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    .main-section {
        .second-section {
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
            padding: 10px;

            .first-head {
                h2 {
                    color: #333;
                    text-align: center;
                    font-family: Barlow;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 10px;
                }

                p {
                    color: #848484;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }
            }

            .icon-section {
                ul {
                    display: flex;
                    /* height: 64px; */
                    /* padding: 10px 168.5px 10.884px 181.5px; */
                    justify-content: center;
                    align-items: center;
                    gap: 20.357px;
                }
            }
        }
    }

    .before-form-dv {
        margin: 0px 45px;

        label {
            color: #000;
            font-size: 16px;
            text-align: left;
            float: left;
        }
    }


    .top-btn {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .form-custom {
        /* width: 500px; */
        padding: 15px 12px 17px 24px;
        border-radius: 12px;
        border: 1px solid rgba(102, 102, 102, 0.35);
    }

    .form-custom2 {
        padding: 15px 12px 17px 24px;
        border-radius: 12px;
        border: 1px solid rgba(102, 102, 102, 0.35);

    }

    .form-btn {
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.35);
        background: #0A65CC;
        padding: 15px 49px 17px 49px;
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
    }

    .craete-form {
        padding: 26px;

        .verify-icon {
            position: relative;

            i {
                position: absolute;
                right: 35px;
                top: 52px;
                color: #7f8082;
            }
        }
    }


    .login-a-tag {
        margin-left: 8px;
    }

    .form-btn2 {
        width: 300px;
        padding: 10px 40px;
        border-radius: 40px;
        background: #1B375C;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
    }


    .slash-icon {
        width: auto;
        color: #666;
        position: absolute;
        right: 15px;
        top: 50px;

        .hide-text {
            font-family: 'Inter', 'Barlow', sans-serif;
            font-weight: 400;
        }
    }

    .modal-btnn {
        border: 1px solid white;
        background: white;
    }

    .height-100 {
        /* height: 72vh */
    }

    .para {
        color: var(--grey-grey-2, #818181);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        text-transform: capitalize;
    }

    .card {

        border: none;
        width: 496px;

        box-shadow: 0px 5px 20px 0px #d2dae3;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .card h6 {
        color: var(--grey-grey-1, #0D0D0D);
        font-family: Barlow;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 39.4px;

        text-transform: capitalize;
    }

    .inputs input {
        width: 40px;
        height: 40px
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0
    }

    .card-2 {
        background-color: #fff;
        padding: 10px;
        width: 350px;
        height: 100px;
        bottom: -50px;
        left: 20px;
        position: absolute;
        border-radius: 5px
    }

    .card-left {
        margin: 45px;
    }

    .modal-body {
        padding: 0px !important;
    }

    // .card-2 .content {
    //     margin-top: 50px
    // }

    // .card-2 .content a {
    //     color: red
    // }

    // .form-control:focus {
    //     box-shadow: none;
    //     border: 2px solid red
    // }
    .checkbox-dv {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        .checkbox-input {
            margin-right: 5px;
        }
    }

    .by-policy-dv {
        text-align: left;
        margin: 10px 0px;

        p {
            a {
                text-decoration: underline;
            }
        }
    }

    .already-account-dv {
        margin-top: 10px;

        .login-a-tag {
            text-decoration: underline;
        }
    }

    .validate {

        height: 40px;
        border-radius: 40px;
        background-color: #1B375C;
        border: 1px solid #1B375C;
        width: 140px
    }

    .password-custom {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .verified-btn {
        margin-top: 0px;
    }
}


@media only screen and (min-width:768px) and (max-width:992px) {
    .create-an-account-section {
        .before-form-dv {
            margin: 0px 0px;
            .craete-form{
                padding: 10px;
                .form-custom{
                    padding: 5px;
                }
                .form-custom2{
                    padding: 5px;
                }
            }
           .verify-icon i {
                position: absolute;
                right: 15px;
                top: 42px;
                color: #7f8082;
            }
            .slash-icon {
                width: auto;
                color: #666;
                position: absolute;
                right: 15px;
                top: 42px;
            }
            .form-btn {
                padding: 5px 25px;
                width: 100%;
            }
        }
    }

    .verified-btn {
        margin-top: 0px;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .create-an-account-section {
        .main-section{
            .second-section{
                .first-head h2 {
                    font-size: 28px;
                }
               .icon-section ul {
                    padding-left: 0px;
                }
                .before-form-dv{
                    .craete-form{
                        padding: 10px;
                        .form-custom{
                            padding: 5px;
                        }
                        .form-custom2{
                            padding: 5px;
                        }
                    }
                   .verify-icon i {
                        position: absolute;
                        right: 15px;
                        top: 42px;
                        color: #7f8082;
                    }
                    .slash-icon {
                        width: auto;
                        color: #666;
                        position: absolute;
                        right: 15px;
                        top: 42px;
                    }
                    .form-btn {
                        padding: 10px 25px;
                        width: 100%;
                    }
                }
            }
        }
        .before-form-dv {
            margin: 0px 0px;
        }
    }

    .verified-btn {
        margin-top: 0px;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
    }
}