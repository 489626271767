.common-header-sec .main-header {
  background: #1B375C;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.common-header-sec .main-header .head-sec-lft {
  display: flex;
  align-items: center;
}
.common-header-sec .main-header .head-sec-lft i {
  color: #fff;
}
.common-header-sec .main-header .head-sec-lft p {
  color: #fff;
  margin-bottom: unset;
}
.common-header-sec .main-header .head-sec-lft .translator ul {
  margin-bottom: unset;
  list-style: none;
  padding-left: 0;
  color: #fff;
}
.common-header-sec .main-header .head-sec-rit {
  display: flex;
  align-items: center;
  justify-content: end;
}
.common-header-sec .main-header .head-sec-rit #dropdownMenuButton1 {
  border: unset;
}
.common-header-sec .main-header .head-sec-rit .btn-lft button {
  background-color: unset;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 5px 20px;
}
.common-header-sec .main-header .head-sec-rit .btn-rit button {
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  padding: 5px 20px;
}
.common-header-sec .main-header .head-sec-rit .dropdown-toggle {
  color: #fff;
}
.common-header-sec .main-header .head-sec-rit .dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 5px;
}
.common-header-sec .header-second {
  background: #FFF;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.common-header-sec .header-second .navbar-collapse {
  animation: slide-in 0.5s;
  transition-timing-function: ease-in;
}
.common-header-sec .header-second .collapse.navbar-collapse {
  animation: slide-in1 0.5ss;
  transition-timing-function: ease-in;
}
@keyframes slide-in {
  from {
    transform: translatey(-100%);
    margin-top: -100%;
  }
  to {
    transform: translatey(0%);
    margin-top: 0%;
  }
}
@keyframes slide-in1 {
  from {
    transform: translatey(0%);
    margin-top: 0%;
  }
  to {
    transform: translatey(-100%);
    margin-top: -100%;
  }
}
.common-header-sec .header-second nav {
  background-color: #fff;
}
.common-header-sec .header-second .dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 5px;
}
.common-header-sec .header-second .navbar-nav li {
  margin: 0 0px;
}
.common-header-sec .header-second .navbar-nav li a {
  font-weight: 600;
  color: #000;
  font-size: 15px;
}
.common-header-sec .header-second .navbar-brand {
  color: #1B375C;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 100% */
  text-transform: uppercase;
}
.common-header-sec .header-second .header-sec-btn1 .last-two-btn1 {
  background-color: unset;
  border: 1px solid #0A65CC;
  color: #0A65CC;
  border-radius: 50px;
  font-size: 14px;
  padding: 12px 25px;
  text-decoration: none;
}
.common-header-sec .header-second .header-sec-btn2 .last-two-btn2 {
  background-color: #0A65CC;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  padding: 12px 20px;
  text-decoration: none;
}

.footer-parent-sec {
  background: rgb(27, 55, 92);
  padding: 85px 0px 30px 0px;
}
.footer-parent-sec .ft-left-dv {
  text-align: left;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-last {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-last a {
  margin-right: 15px;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-2 {
  padding: 10px 0px;
  text-align: left;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-2 span {
  color: #fff;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-2 a {
  color: #fff;
  text-decoration: none;
}
.footer-parent-sec .ft-left-dv .ft-after-logo .border-bottom-none {
  border-bottom: none;
}
.footer-parent-sec .ft-rt-dv {
  border: 1px solid rgb(255, 255, 255);
  height: 292px;
  border-radius: 8px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0px 40px;
}
.footer-parent-sec .ft-rt-dv .ft-input {
  width: 100%;
  position: relative;
}
.footer-parent-sec .ft-rt-dv .ft-input input {
  width: 100%;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px;
  border: 0.5px solid rgb(183, 183, 183);
}
.footer-parent-sec .ft-rt-dv .ft-input input::-moz-placeholder {
  padding: 0 8px;
  color: #9b9b9b;
  font-size: 12px;
}
.footer-parent-sec .ft-rt-dv .ft-input input::placeholder {
  padding: 0 8px;
  color: #9b9b9b;
  font-size: 12px;
}
.footer-parent-sec .ft-rt-dv .ft-input button {
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(274.05deg, #FF354D -37.86%, #FE6108 125.4%);
  box-shadow: 0px 1px 12px 0px rgba(254, 97, 8, 0);
  border-radius: 5px;
  border: none;
  padding: 6px 18px;
  color: #FFF;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer-parent-sec .right-img-dv {
  text-align: left;
}
.footer-parent-sec .right-img-dv h4 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}
.footer-parent-sec .right-img-dv p {
  margin-bottom: 0px;
  color: #fff;
  font-size: 12px;
}
.footer-parent-sec .right-img-dv h3 {
  font-size: 28px;
  color: #FE6108;
  text-transform: uppercase;
}

.bottom-st-section .bottom-strip-head .bottom-strip {
  background: rgb(0, 0, 0);
  padding: 15px 0px;
  text-align: center;
}
.bottom-st-section .bottom-strip-head .bottom-strip p {
  margin-bottom: 0px;
  color: rgb(209, 213, 219);
}
.bottom-st-section .bottom-strip-head .bottom-strip .right-policies a {
  text-decoration: none;
  color: #fff;
}
.bottom-st-section .bottom-strip-head .bottom-strip .right-policies a:nth-child(1) {
  margin-right: 20px;
}
.bottom-st-section .bottom-strip-head .bottom-strip .right-policies a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .footer-parent-sec .align-items-center-md {
    align-items: center;
  }
  .footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-last {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    margin: 10px 0px 20px 0px;
  }
  .footer-parent-sec .right-img-dv {
    text-align: center;
  }
  .footer-parent-sec .ft-rt-dv {
    height: 340px;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv .inner-img {
    width: 120px;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv .inner-img img {
    width: 100%;
  }
  .footer-parent-sec .ft-rt-dv .ft-input {
    margin-top: 10px;
  }
  .footer-parent-sec .ft-rt-dv .ft-input input {
    padding: 5px 100px 5px 5px;
  }
  .footer-parent-sec .ft-rt-dv .ft-input button {
    padding: 12px 10px;
    color: #fff;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .common-header-sec .main-header .head-sec-rit {
    margin: 5px 0;
    align-items: center;
    justify-content: center;
  }
  .common-header-sec .main-header .head-sec-rit .btn-lft button {
    padding: 3px 15px;
    font-size: 15px;
  }
  .common-header-sec .main-header .head-sec-rit .btn-rit button {
    padding: 3px 15px;
    font-size: 15px;
  }
  .common-header-sec .header-second {
    display: block;
  }
  .footer-parent-sec .ft-left-dv .ft-after-logo .ft-a-tag-last {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    margin: 10px 0px 20px 0px;
  }
  .footer-parent-sec .right-img-dv {
    text-align: center;
  }
  .footer-parent-sec .ft-rt-dv {
    height: 340px;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv {
    margin: unset !important;
  }
  .footer-parent-sec .ft-rt-dv .ft-input {
    margin-top: 10px;
  }
  .footer-parent-sec .ft-rt-dv .ft-input input {
    padding: 0px 100px 0px 5px;
  }
  .footer-parent-sec .ft-rt-dv .ft-input button {
    padding: 6px 10px;
    color: #fff;
  }
}/*# sourceMappingURL=common.css.map */