.jobfind-description-section .second-section {
  border-radius: 4px;
  background: #FFF;
  margin-top: 30px;
  padding: 20px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.jobfind-description-section .job-dep-parent {
  text-align: left;
}
.jobfind-description-section .job-dep-parent h3 {
  color: var(--gray-900, #18191C);
  font-size: 20px;
}
.jobfind-description-section .job-dep-parent p {
  color: var(--gray-600, #5E6670);
  font-size: 16px;
}
.jobfind-description-section .requirement-par {
  text-align: left;
}
.jobfind-description-section .requirement-par h3 {
  color: var(--gray-900, #18191C);
  font-size: 20px;
}
.jobfind-description-section .requirement-par li {
  color: var(--gray-600, #5E6670);
  font-size: 16px;
}
.jobfind-description-section .desirable-dev {
  text-align: left;
}
.jobfind-description-section .desirable-dev h3 {
  color: var(--gray-900, #18191C);
  font-size: 20px;
}
.jobfind-description-section .desirable-dev li {
  color: var(--gray-600, #5E6670);
  font-size: 16px;
}
.jobfind-description-section .benefits-dev {
  text-align: left;
}
.jobfind-description-section .benefits-dev h3 {
  color: var(--gray-900, #18191C);
  font-size: 20px;
}
.jobfind-description-section .benefits-dev li {
  color: var(--gray-600, #5E6670);
  font-size: 16px;
}
.jobfind-description-section .top-header {
  display: flex;
  justify-content: space-between;
}
.jobfind-description-section .top-head h3 {
  font-size: 24px;
}
.jobfind-description-section .top-head p span:nth-child(1) {
  color: #474C54;
  font-size: 18px;
  font-weight: 400;
}
.jobfind-description-section .top-head p span:nth-child(2) {
  border-radius: 3px;
  background: #0BA02C;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px;
}
.jobfind-description-section .top-head p span:nth-child(3) {
  border-radius: 20px;
  background: #FFEDED;
  padding: 4px 12px;
  color: #E05151;
  font-size: 14px;
  font-weight: 400;
}
.jobfind-description-section .top-btn .top-btn-tag {
  border-radius: 4px;
  background: #0BA02C;
  width: 248px;
  padding: 16px 32px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.jobfind-description-section .left-card-section {
  border-radius: 8px;
  border: 2px solid #E7F0FA;
  background: #FFF;
}
.jobfind-description-section .border-right {
  border-right: 2px solid #E7F0FA;
}
.jobfind-description-section .left-section {
  padding: 20px 0;
}
.jobfind-description-section .left-section h6 {
  color: #18191C;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.jobfind-description-section .left-section h5 {
  color: #0BA02C;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.jobfind-description-section .left-section p {
  color: #8c8876;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.jobfind-description-section .left-section2 {
  padding: 20px;
}
.jobfind-description-section .left-section2 p {
  color: #767F8C;
  font-size: 16px;
  font-weight: 400;
}
.jobfind-description-section .left-section2 h5 {
  color: #18191C;
  font-size: 16px;
  font-weight: 500;
}
.jobfind-description-section .left-card-second {
  border-radius: 8px;
  border: 2px solid #E7F0FA;
  background: #FFF;
}
.jobfind-description-section .border-bottom {
  border: 2px solid #E7F0FA;
}
.jobfind-description-section .card-list {
  display: flex;
  /* padding: 10px; */
}
.jobfind-description-section .list-head h3 {
  color: #18191C;
  /* padding: 10px; */
  font-size: 18px;
  font-weight: 500;
}
.jobfind-description-section .list-top-heading {
  margin: 20px;
  margin-top: 37px;
}
.jobfind-description-section .card-pad {
  padding: 10px;
}
.jobfind-description-section .card-pad p {
  color: #767F8C;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.jobfind-description-section .card-pad h6 {
  color: #18191C;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .jobfind-description-section .card-list {
    display: flex;
    /* padding: 10px; */
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .jobfind-description-section .card-pad p {
    margin-bottom: 0px;
  }
  .jobfind-description-section .top-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .jobfind-description-section .top-head {
    text-align: left;
  }
  .jobfind-description-section .top-head h3 {
    font-size: 18px;
  }
  .jobfind-description-section .top-head p {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .jobfind-description-section .top-head p span {
    color: #474C54;
    font-size: 14px !important;
    font-weight: 400;
  }
  .jobfind-description-section .top-btn {
    width: 100%;
  }
  .jobfind-description-section .top-btn .top-btn-tag {
    width: 100% !important;
    height: 38px !important;
    padding: 0px;
  }
  .jobfind-description-section .card-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .jobfind-description-section .card-list .card-list-child-dv {
    padding: 5px 10px;
  }
  .jobfind-description-section .card {
    margin-top: 12px;
  }
  .jobfind-description-section .job-dep-parent h3 {
    text-align: left;
  }
  .jobfind-description-section .job-dep-parent p {
    text-align: justify;
  }
  .jobfind-description-section .requirement-par h3 {
    text-align: left;
  }
  .jobfind-description-section .requirement-par ul {
    padding-left: 16px;
  }
  .jobfind-description-section .requirement-par ul li {
    text-align: left;
  }
  .jobfind-description-section .desirable-dev h3 {
    text-align: left;
  }
  .jobfind-description-section .desirable-dev ul {
    padding-left: 16px;
  }
  .jobfind-description-section .desirable-dev ul li {
    text-align: left;
  }
  .jobfind-description-section .benefits-dev h3 {
    text-align: left;
  }
  .jobfind-description-section .benefits-dev ul {
    padding-left: 16px;
  }
  .jobfind-description-section .benefits-dev ul li {
    text-align: left;
  }
}/*# sourceMappingURL=JobFindDescription.css.map */