.cv-for-emp-content-sec .bg-form {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 0px 30px;
  margin: 20px 0px;
}
.cv-for-emp-content-sec .bg-form .arror-icon {
  padding: 20px;
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .arror-icon a {
  text-decoration: none;
}
.cv-for-emp-content-sec .bg-form .first-head {
  padding: 20px;
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .first-head h2 {
  font-size: 24px;
}
.cv-for-emp-content-sec .bg-form .form-banner {
  padding: 0px 20px;
}
.cv-for-emp-content-sec .bg-form .form-banner .cv-for-employer-input {
  visibility: hidden;
}
.cv-for-emp-content-sec .bg-form .form-banner .cv-upload {
  border-radius: 12px;
  border: 1px dashed #0A65CC;
  padding: 15px;
  cursor: pointer;
}
.cv-for-emp-content-sec .bg-form .form-banner .resume p {
  margin: unset;
  color: rgba(102, 102, 102, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cv-for-emp-content-sec .bg-form .form-banner .resume h4 {
  color: #0A65CC;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p {
  text-align: left;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p span {
  margin-left: 5px;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-para p span a {
  color: #0A65CC;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-btn {
  text-align: center;
  padding: 40px 0px;
}
.cv-for-emp-content-sec .bg-form .form-banner .upload-btn .upload-btn-a {
  border-radius: 40px;
  background: #1B375C;
  width: 300px;
  padding: 10px 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .cv-for-emp-content-sec .bg-form {
    padding: 0px 10px;
  }
  .cv-for-emp-content-sec .bg-form .bg-form .first-head h2 {
    font-size: 17px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .resume h4 {
    font-size: 14px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .resume p {
    font-size: 10px;
    text-align: left;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-para p {
    text-align: center;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-para p span {
    margin-left: 5px;
    display: block;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-btn {
    text-align: center;
    padding: 0px 0px 30px 0px;
  }
  .cv-for-emp-content-sec .bg-form .form-banner .upload-btn .upload-btn-a {
    width: 190px !important;
  }
  .cv-for-emp-content-sec .upload img {
    width: 18px;
  }
  .cv-for-emp-content-sec .list-section ul {
    flex-direction: column;
  }
  .cv-for-emp-content-sec .heading a {
    margin-left: 50px;
  }
  .cv-for-emp-content-sec .form-btn2 {
    width: 190px !important;
  }
  .cv-for-emp-content-sec .bg-second {
    padding: 0px !important;
  }
  .cv-for-emp-content-sec .ptn {
    margin: 20px;
  }
  .cv-for-emp-content-sec .para span a {
    margin: 37px;
  }
  .cv-for-emp-content-sec .form-banner {
    padding: 0px 9px !important;
  }
  .cv-for-emp-content-sec .top-btn {
    margin-top: 0px !important;
  }
  .cv-for-emp-content-sec .form-btn {
    padding: 2px 24px 6px 28px !important;
  }
  .cv-for-emp-content-sec .form-btn2 {
    margin-top: 10px !important;
  }
}/*# sourceMappingURL=CvForEmployer.css.map */