.resumes-listing-sec .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.resumes-listing-sec .resume-info h5 {
  text-align: left;
  color: #666;
  font-size: 12px;
  font-weight: 400;
}
.resumes-listing-sec .resume-info h2 {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}
.resumes-listing-sec .resume-info ul {
  padding: unset;
}
.resumes-listing-sec .resume-info ul li {
  list-style: none;
  color: #777;
  font-size: 12px;
  font-weight: 400;
}
.resumes-listing-sec .resume-info ul li img {
  padding: 0 5px 0 0;
}
.resumes-listing-sec .resume-info ul li:nth-child(2) {
  padding: 0 20px;
}
.resumes-listing-sec .resume-info h4 {
  text-align: left;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
}
.resumes-listing-sec .view-btn a {
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .resumes-listing-sec {
    padding: unset !important;
  }
  .resumes-listing-sec .resume-info ul {
    display: block !important;
  }
  .resumes-listing-sec .resume-info ul li {
    margin: 0 0 10px 0;
  }
  .resumes-listing-sec .resume-info ul li:nth-child(2) {
    padding: unset;
  }
  .resumes-listing-sec .resume-img {
    padding: unset !important;
  }
  .resumes-listing-sec .view-btn a {
    padding: 4px 22px;
    font-size: 14px;
  }
}/*# sourceMappingURL=ResumeListing.css.map */