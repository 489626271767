.interview-question-listing .container {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.interview-question-listing h1 {
  color: #141414;
  font-family: Barlow;
  font-size: 32px;
  font-weight: 700;
}
.interview-question-listing h3 {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
}
.interview-question-listing p {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.interview-question-listing .col-lg-4 {
  padding: 30px;
}
.interview-question-listing .col-lg-4 img {
  padding: 0 0 15px 0;
}
.interview-question-listing .custm-ques-sec:nth-child(3n+0) {
  border: 1px solid #DDD;
  border-top: unset;
}
.interview-question-listing .custm-ques-sec:nth-child(1n+1) {
  border-bottom: 1px solid #DDD;
}
.interview-question-listing .view-btn a {
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(27, 55, 92, 0.64);
  background: var(--light-background-transparent, rgba(255, 255, 255, 0));
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .interview-question-listing h1 {
    font-size: 25px;
  }
  .interview-question-listing h3 {
    font-size: 18px;
  }
  .interview-question-listing .view-btn a {
    padding: 4px 22px;
    font-size: 14px;
  }
}/*# sourceMappingURL=InterViewQuestionListing.css.map */