.help-hire {
  background: #F6F6F6;
}
.help-hire h1 {
  color: #1B375C;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}
.help-hire .custm-card {
  padding: 20px;
  border-radius: 15px;
  background: #FFF;
}
.help-hire .custm-card h3 {
  color: var(--Main, #073E87);
  font-size: 20px;
  font-weight: 600;
}
.help-hire .custm-card p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.help-hire .custm-card ul {
  padding: unset;
  justify-content: space-around;
}
.help-hire .custm-card ul li {
  list-style: none;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .help-hire h1 {
    color: #1B375C;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
  }
  .sponsor .custm-bud-img img {
    width: 400px;
    height: 350px;
    -o-object-fit: unset;
       object-fit: unset;
    border-radius: 10px;
    border-radius: 25px;
    border: 10px solid #fff;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .help-hire h1 {
    color: #1B375C;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
}/*# sourceMappingURL=helphire.css.map */