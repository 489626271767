.career-start-search-section {
    margin: 20px 0px;

    .career-section {
        background-image: url("../../../../../../public/assets/career-start/career-start-banner.png");
        background-position: 100%;
        background-size: cover;
        padding: 85px;
    }

    .career-section .career-top-heading h2 {
        text-align: center;
        font-size: 50px;
        font-weight: 400;
    }

    .career-section .career-top-heading span {
        text-align: center;
        color: #1B375C;
        font-size: 50px;
        font-weight: 700;
    }

    .career-section .career-second-heading h6 {
        color: #1B375C;
        font-size: 18px;
        font-weight: 500;
        background-color: #fff;
        width: 40%;
        border-radius: 10px;
        padding: 5px;
    }

    .career-section .career-second-heading span {
        color: #1B375C;
        font-size: 18px;
        font-weight: 700;
    }

    .career-section .custom-form-section {
        display: flex !important;
    }

    .search-custom {
        position: relative;
        width: 335px;
        &:nth-child(1) .form-custom{
            border-radius: 8px 0px 0px 8px;
        }
        &:nth-child(2) .form-custom{
            border-radius: 0px;
        }
        &:nth-child(3) .form-custom{
            border-radius: 0px;
        }
    }

    .search-custom .form-custom {
        height: 53px;
        padding: 0 0 0 50px;
    }

    // .search-custom input::-moz-placeholder {
    //     padding: 0 0 0 50px;
    // }

    // .search-custom input::placeholder {
    //     padding: 0 0 0 50px;
    // }

    .search-custom img {
        position: absolute;
        top: 11px;
        left: 15px;
        color: #b0a7a7;
    }

    .form-custom-btn {
        border-radius: 0px 8px 8px 0px;
        background: #1B375C;
        color: #FFF;
        width: 102px;
        height: 53px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
    }

    /*=============================================== silder-start-css=============================== */
    .footer-list-card ul li {
        list-style: none;
        color: #565656;
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
    }

    .footer-list-card ul li img {
        margin-right: 10px;
    }

    .footer-list-card ul {
        padding: unset;

    }

    .card-custom {
        border: none;
        padding: 20px !important;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .card-first-custom {
        border: none;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .card-second-custom {
        border: none;
        padding: 50px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .card-custom-2 {
        border: none;
        padding: 35px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .card-custom-3 {
        border: none;
        padding: 30px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .footer-heading h3 {
        color: #141414;

        font-size: 16px;

        font-weight: 500;


    }

    .footer-heading span a {
        color: #2B85FF;
        text-decoration: none;

        font-size: 15px;
        margin-left: 15px;


        font-weight: 500;
        border-radius: 3px;
        background: rgba(43, 133, 255, 0.10);

    }

    .footer-icon span a {
        color: #FE6108;
        text-decoration: none;

        font-size: 15px;
        margin-left: 15px;


        font-weight: 500;
        border-radius: 3px;
        background: rgba(254, 97, 8, 0.10);

    }

    .footer-heading p {
        color: #565656;

        font-size: 15px;

        font-weight: 400;



    }



    .second-section {
        border-radius: 4px;
        background: #FFF;
        margin-top: 30px;
        padding: 30px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .card-heading h3 {


        color: #141414;

        font-size: 26px;

        font-weight: 700;

    }

    .card-second-heading h6 {
        color: #141414;
        text-align: center;

        font-size: 20px;

        font-weight: 500;
    }


}


@media only screen and (min-width:768px) and (max-width:992px) {
    .career-start-search-section {
        .career-section {
            padding: 0px;

            .custom-form-section {
                display: flex !important;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;

            }

            .search-custom .form-custom {
                height: 41px;
                width: 100%;
                margin-top: 0px;
                border-radius: 5px;
            }

            .form-custom-btn {
                width: 100%;
                height: 41px;
                font-size: 15px;
                margin-top: 11px;
                margin-bottom: 11px;
                border-radius: 5px;
                border: none;
                padding: 10px 20px;
            }

            .career-top-heading {
                h2 {
                    font-size: 28px;
                }

                span {
                    font-size: 28px;
                }
            }

            .career-second-heading {
                h6 {
                    color: #1B375C;
                    font-size: 24px;
                    font-weight: 500;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 10px;
                    padding: 5px;
                }
            }
        }
    }

}

@media only screen and (min-width:200px) and (max-width:767px) {
    .career-start-search-section {
        .career-section {
            padding: 0px;

            .custom-form-section {
                display: flex !important;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;

            }

            .search-custom .form-custom {
                height: 41px;
                width: 100%;
                margin-top: 6px;
                border-radius: 0px;
            }

            .form-custom-btn {
                width: 100%;
                height: 41px;
                font-size: 15px;
                margin-top: 11px;
                margin-bottom: 11px;
                border-radius: 5px;
                border: none;
                padding: 10px 20px;
            }

            .career-top-heading {
                h2 {
                    font-size: 28px;
                }

                span {
                    font-size: 28px;
                }
            }

            .career-second-heading {
                h6 {
                    color: #1B375C;
                    font-size: 16px;
                    font-weight: 500;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 10px;
                    padding: 5px;
                }
            }
        }
    }

}