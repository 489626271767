$color_1: #1B375C;
$color_2: #FFF;
$color_3: #b0a7a7;

/*================================ media query start ========================================*/
.search-resume-sec-parent {
    .search-resumes-section {
        padding: 50px;

        .filter-bg {
            border-radius: 4px;
            background: #FFF;
            padding: 30px;
            padding-bottom: 80px;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(29, 95, 181, 0.20) inset;
        }

        .search-resumes-bg {
            border-radius: 4px;
            padding: 20px;
            background: #FFF;
            box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

            .search-resumes-display {
                display: flex;
                justify-content: space-between;

                .search-heading {
                    h3 {
                        color: $color_1;
                        font-size: 30px;
                        font-weight: 700;
                    }
                }

                // .srch {
                //     display: flex;
                // }

                


                .search-btn1 {
                    border-radius: 10px;
                    border: 1px solid #1B375C;
                    font-size: 16px;
                    background: #fff;
                    color: $color_1;
                    padding: 10px 30px;
                    text-decoration: none;
                }

                .search-btn2 {
                    border-radius: 10px;
                    border: 1px solid #1B375C;
                    font-size: 16px;
                    background: #fff;
                    color: $color_1;
                    padding: 10px 20px;
                    text-decoration: none;
                }

                .search-btn3 {
                    padding: 10px 20px;
                    border-radius: 10px;
                    border: 1px solid #1B375C;
                    background: #1B375C;
                    font-size: 16px;
                    color: $color_2;
                    text-decoration: none;
                }


            }

            .custom-form-section {
                display: flex !important;

                .search-custom {
                    position: relative;

                    .form-custom {
                        height: 47px;
                        // width: 351px;
                        padding-left: 35px;
                    }

                    .form-custom.form-cus-one {
                        border-radius: 8px 0px 0px 8px;
                    }

                    .form-custom.form-cus-two {
                        border-radius: 0px;
                    }

                    // input {
                    //     &::-moz-placeholder {
                    //         padding: 0 0 0 50px;
                    //     }

                    //     &::placeholder {
                    //         padding: 0 0 0 50px;
                    //         color: rgba(20, 20, 20, 0.50);
                    //         font-size: 14px;
                    //     }
                    // }

                    img {
                        position: absolute;
                        top: 12px;
                        left: 11px;
                        color: $color_3;
                    }
                }

                .form-custom-btn {
                    border-radius: 0px 8px 8px 0px;
                    background: #1B375C;
                    color: $color_2;
                    border: none;
                    width: 144px;
                    height: 47px;
                    font-size: 13px;
                    font-weight: 500;
                }

                .fitter-icon {
                    height: 24px;
                    width: 24px;
                }

            }

            .advance-search-parent {
                margin-left: auto;
                padding-right: 10px;
            }

            .advance-icon {
                p {
                    color: $color_1;
                    margin-top: 9px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .dropdown-custom-section {
                display: flex;
                justify-content: flex-end;

                .dropdown-custom-btn {
                    border-radius: 4px;
                    border: 1px solid var(--black-50, #53545C);
                }
            }
        }

    }
}




@media only screen and (min-width:768px) and (max-width:920px) {
    .search-resume-sec-parent {
        .search-resumes-section {
            .custom-form-section {
                display: flex !important;
                flex-direction: column;
            }

            .search-custom {
                .form-custom {
                    margin-top: 7px;
                }
            }

            .form-custom-btn {
                margin-top: 18px;
            }

            .search-btn3 {
                margin-top: 15px;
            }

            .dropdown-custom-section {
                display: flex;
                flex-direction: column;
            }

            .dropdown-custom-btn {
                margin-top: 17px;
            }
        }
    }
}

@media only screen and (min-width:568px) and (max-width:992px) {
    .search-resume-sec-parent {
        .search-resumes-section {
            padding: 50px 0px !important;


            .dropdown-custom-section {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start !important;
            }

            .search-area {
                flex-direction: column;
            }

            .custom-form-section {
                flex-direction: row;
                flex-wrap: wrap;

                .search-custom {
                    .form-custom {
                        width: 250px !important;
                    }
                }

                .form-custom-btn {
                    margin: 7px 0 0 0;
                }
            }

            .search-resumes-display {
                .search-btn1 {
                    padding: 9px 3px;
                    font-size: 12px !important;
                }

                .search-btn2 {
                    padding: 9px 23px;
                    font-size: 12px !important;
                }

                .search-btn3 {
                    padding: 9px 14px;
                    font-size: 12px !important;
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .search-resume-sec-parent {
        .search-resumes-section {
            padding: 50px 0px !important;

            .search-resumes-bg {
                .search-resumes-display {
                    display: flex;
                    flex-direction: column;

                    .search-heading {
                        h3 {
                            font-size: 20px;
                        }
                    }

                    .search-btn1 {
                        padding: 9px 3px;
                        font-size: 12px;
                        // margin: 0 0 10px 0;
                    }

                    .search-btn2 {
                        padding: 9px 3px;
                        font-size: 12px;
                        // margin: 0 0 10px 0;
                    }

                    .search-btn3 {
                        padding: 9px 3px;
                        font-size: 12px;
                    }
                }
               
                .search-area {
                    flex-direction: column;
                }
    
                .custom-form-section {
                    display: flex !important;
                    flex-direction: column;

                    .search-custom {
                        input {
                            width: auto !important;
                        }
                    }
                }
            }

            .advance-search-parent {
                display: flex !important;
                justify-content: center !important;
                margin-left: unset !important;
            }
            .advance-icon {
                display: flex !important;
                flex-direction: column;
              

                p {
                    // display: none;
                }
            }

            .dropdown-custom-section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .search-custom {
                .form-custom {
                    width: 237px;
                    margin-top: 8px;
                }
            }

            .fitter-icon {
                // display: none;
            }

            .form-custom-btn {
                margin-top: 10px;
            }

            .dropdown-custom-btn {
                margin-top: 17px;
            }
            .form-custom-btn {
                border-radius: 8px !important;
            } 
        }
    }
}