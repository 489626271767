.videoint .custm-card {
  background-image: url(../../../../../../public/assets/images/matching-hiring/crd.png);
  background-size: cover;
  background-position: 100%;
  height: 350px;
  padding: 30px 40px;
}
.videoint .custm-card h3 {
  color: #1B375C;
  font-size: 28px;
  font-weight: 700;
}
.videoint .custm-card ul {
  padding: unset;
}
.videoint .custm-card ul li {
  color: #1E1E1E;
  text-align: justify;
  font-size: 18px;
  margin: 0 0 0 20px;
}
.videoint .custm-card ul li:nth-child(1) {
  list-style: none;
  margin: unset;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .videoint .custm-card {
    padding: 30px 30px;
  }
  .videoint .custm-card h3 {
    font-size: 20px;
  }
  .videoint .custm-card ul li {
    font-size: 15px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .videoint .custm-card {
    height: auto;
    padding: 10px 10px;
    background-position: center;
  }
  .videoint .custm-card h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .videoint .custm-card ul li {
    font-size: 16px;
  }
}/*# sourceMappingURL=videoint.css.map */