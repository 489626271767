@media only screen and (min-width: 768px) and (max-width: 992px) {
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .header-banner .banner-text h1 {
    font-size: 30px;
  }
  .header-banner .banner-text {
    transform: translate(-50%, -55%);
  }
  .header-banner .banner-text button {
    padding: 10px 15px;
    font-size: 13px;
  }
  .bannerafter-header .achievements-lft h1 {
    font-size: 30px;
    text-align: center;
  }
  .bannerafter-header .achievements-lft h1 br {
    display: none;
  }
  .bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 22px;
  }
  .job-demand .job-box2 {
    margin-bottom: 10px;
  }
  .work-location h1 {
    font-size: 30px;
  }
  .job-opening h1 {
    font-size: 30px;
  }
  .job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 15px;
  }
  .benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .job-demand h1 {
    font-size: 30px;
  }
  .impaneled-companies h1 {
    font-size: 30px;
  }
  .impaneled-companies .company-slider {
    width: 210px !important;
    border-radius: 20px;
    background: #FFF;
    padding: 0 10px;
    box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
    margin: 0px 7px;
  }
  .job-fair h1 {
    font-size: 30px;
  }
  .testimonial h1 {
    font-size: 30px;
  }
  .heading-txt {
    margin: 10px 0 !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .job-opening .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    overflow-x: scroll;
  }
  .common-header-sec .main-header .row > .col-lg-6 {
    justify-content: center;
  }
  .common-header-sec .main-header .head-sec-lft p {
    font-size: 13px;
  }
  .common-header-sec .main-header .head-sec-lft {
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .header-banner .banner-text {
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .header-banner .banner-text .find-job-btn {
    padding: 5px 15px;
  }
  .header-banner .banner-text h1 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .header-banner .banner-text button {
    width: 125px;
    height: 35px;
    font-size: 10px;
    padding: 0;
  }
  .footer-parent-sec .ft-rt-dv .inner-img-dv {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .bannerafter-header .achievements-lft h1 {
    font-size: 22px;
    line-height: 38px;
    text-align: center;
  }
  .bannerafter-header .achievements-lft h1 br {
    display: none;
  }
  .bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .bannerafter-header {
    padding: 20px 5px;
  }
  .achievements-rit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
  }
  .achievements-rit .counts {
    justify-content: flex-start;
  }
  .achievements-rit .counter-img img {
    padding: 0px;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .achievements-rit .counter-txt p {
    margin-bottom: 0px;
  }
  .work-location h1 {
    font-size: 30px;
  }
  .work-location .heading-txt {
    margin: 0px 0 !important;
  }
  .benifits .key-benifits {
    padding: 20px;
  }
  .benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .job-demand h1 {
    font-size: 30px;
  }
  .job-demand .job-box h4 {
    font-size: 18px;
  }
  .job-demand .job-box .company button {
    font-size: 14px;
  }
  .job-demand .job-box .company .company-txt {
    margin: 0 0 0 10px !important;
  }
  .job-demand .job-box .job-type p {
    margin: 0 15px 0 5px !important;
  }
  .impaneled-companies h1 {
    font-size: 30px;
  }
  .impaneled-companies .company-slider {
    width: 100% !important;
    border-radius: 20px;
    background: #FFF;
    padding: 0 10px;
    box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
  }
  .job-fair h1 {
    font-size: 30px;
  }
  .job-fair .software .intro h4 {
    font-size: 16px;
  }
  .testimonial h1 {
    font-size: 30px;
  }
  .job-opening h1 {
    font-size: 30px;
  }
  .job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    margin: 0;
    font-size: 15px;
  }
  .job-fair .software {
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=homeresponsive.css.map */