.recommended-jobs-slider-job-section {
    margin: 10px 0px 30px 0px;

    .second-section {
        .slick-slider {

            .slick-next:before,
            .slick-prev:before {
                font-size: 26px;
                line-height: 1;
                opacity: .75;
                color: rgba(27, 55, 92, 1);
            }

            .slick-next,
            .slick-prev {
                font-size: 0;
                line-height: 0;
                top: -20%;
                width: 20px;
                height: 20px;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                cursor: pointer;
                color: transparent;
                border: none;
                outline: 0;
                background: 0 0;
            }

            .slick-next {
                right: 40px !important;
            }

            .slick-prev {
                right: 90px !important;
                left: unset;
            }

            .slick-next:before {
                position: absolute;
                content: "\f054";
                // left: 0;
                color: #ffffff;
                font-size: 12px;
                font-family: fontawesome;
                background: rgba(27, 55, 92, 1);
                border-radius: 50%;
                width: 25px;
                height: 25px;
                line-height: 24px;
                border: 1px solid rgba(27, 55, 92, 1);
            }

            .slick-prev:before {
                position: absolute;
                content: "\f053";
                // left: 0;
                color: #ffffff;
                font-size: 12px;
                font-family: fontawesome;
                background: rgba(27, 55, 92, 1);
                border-radius: 50%;
                width: 25px;
                height: 25px;
                line-height: 24px;
                border: 1px solid rgba(27, 55, 92, 1);
            }
        }
    }


    /* silder css start */
    .second-section {
        border-radius: 4px;
        background: #FFF;
        margin-top: 30px;
        padding: 30px 15px 15px 15px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .similer-post-heading {
            h2 {
                font-size: 32px;
                font-weight: 700;
                text-align: left;
                // margin-bottom: 30px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    .card {
        padding: 20px !important;
        margin: 0px 10px;

        .card-sub-dv {
            display: flex;
        }

        .footer-heading {
            text-align: left;

            h3 {
                color: #141414;
                font-size: 20px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            p {
                color: #565656;
                font-size: 14px;
                font-weight: 400;

                span {
                    a {
                        color: #2B85FF;
                        text-decoration: none;
                        font-size: 12px;
                        margin-left: 25px;
                        font-weight: 500;
                        border-radius: 3px;
                        background: rgba(43, 133, 255, 0.10);
                        padding: 3px 6px;
                    }
                }
            }
        }

        .footer-list-card {
            text-align: left;

            ul {
                padding: unset;

                li {
                    list-style: none;
                    color: #565656;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 5px;

                    img {
                        margin-left: 10px;
                        // padding: 0 3px;
                        display: inline-block;
                    }
                }
            }
        }
    }



}


@media only screen and (min-width:200px) and (max-width:767px) {
    .recommended-jobs-slider-job-section {
        .card {
            margin: 0px 2px;

            .footer-heading {
                p {
                    color: #565656;
                    font-size: 14px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;

                    span {
                        a {
                            margin-left: 0;
                        }
                    }
                }
            }

            padding: 20px !important;

            .card-sub-dv {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media only screen and (min-width:767px) and (max-width:992px) {
    .recommended-jobs-slider-job-section {
        .card {
            margin: 0px 2px;

            .footer-heading {
                p {
                    color: #565656;
                    font-size: 14px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;

                    span {
                        a {
                            margin-left: 0;
                        }
                    }
                }
            }

            padding: 20px !important;

            .card-sub-dv {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }
    }
}