.about-us-content-sec {
    .main-head {
        padding: 1px;
        border-radius: 4px;
        background: #F4FAFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;
    }

    .main-head h2 {
        color: #1B375C;
        font-size: 22px;
        font-weight: 700;
        padding: 15px;
        text-align: center;
        text-transform: capitalize;

    }

    .main-section {
        padding: 40px;
    }



    .first-head h2 {
        color: #1B375C;

        font-size: 60px;

        font-weight: 700;



        margin-top: 152px;
    }

    .first-head span {
        color: #0A65CC;

        font-size: 60px;

        font-weight: 700;
        line-height: 137.5%;

    }

    .banner-section {
        border-radius: 4px;
        background: #FBFBFB;


    }

    .banner-second {
        padding: 55px;
        border-radius: 4px;
        background: #FBFBFB;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    /*================== slider-section-start-css ==================*/
    .slider-section {
        border-radius: 4px;
        background: #FFF;
    }

    .about-section {
        border-radius: 4px;
        background: #FBFBFB;
        padding: 66px;

    }

    .about-us-heading h2 {
        color: #1E1E1E;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);

        font-size: 48px;

        font-weight: 700;

        justify-items: center;
        letter-spacing: 0.96px;
        padding: 25px;
    }

    .about-us-heading p {
        color: #1E1E1E;

        // font-size: 18px;
        font-style: normal;
        font-weight: 400;

        padding: 20px;
    }

    .about-us-heading button {
        border-radius: 5px;
        background: #1B375C;
        box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
        color: #FFF;
        width: 157px;
        height: 48px;
        font-size: 18px;

        font-weight: 500;


    }

    /*======================= about-section-start-css====================== */

    .about-second-section h2 {
        color: #1B375C;
        padding: 10px;
        font-size: 40px;
        font-weight: 700;
    }

    .about-second-section p {
        color: #1E1E1E;
        font-weight: 400;
        padding: 15px;
        letter-spacing: 0.36px;
    }

    .about-list-section ul li {
        list-style: none;
        color: #1E1E1E;
        font-weight: 400;
        letter-spacing: 0.36px;
        line-height: 30px;
    }

    .about-list-section ul {
        position: relative;
    }

    .about-list-section ul li:before {
        position: absolute;
        content: "\f128";
        left: 25px;
        /* top: 5px; */
        color: #000000;
        font-size: 18px;
        margin-left: -16px;
        font-family: fontawesome;

    }

    /*================= card-section-start-css ==================*/
    .card-custom {
        border-radius: 30px;
        background: #FFF;
        border: 1px solid rgb(19, 205, 238);
        padding: 20px;
        -webkit-box-shadow: -6px -1px 18px -4px rgba(0, 0, 0, 0.53);
        -moz-box-shadow: -6px -1px 18px -4px rgba(0, 0, 0, 0.53);
        box-shadow: -6px -1px 18px -4px rgba(0, 0, 0, 0.53);
    }

    .card-img {
        text-align: center;
        margin-top: 40px;


    }

    .card-head {
        text-align: center;

    }

    .card-head h5 {
        margin-top: 20px;

    }

    .card-head p {
        margin-top: 20px;

    }
    .card-second-section {
        margin-left: 60px;
        h2 {
            color: #1B375C;
            font-size: 44px;
            font-weight: 700;
        }
        p {
            color: #1E1E1E;
            // font-size: 18px;
            font-weight: 400;
        }
        button {
            border-radius: 5px;
            background: #1B375C;
            box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
            color: #FFF;
            width: 157px;
            height: 48px;
            font-size: 18px;
            font-weight: 700;
        }
    }



    /*=============== brand-section-start-css================== */


    .brand-section {
        background: #F2F2F2;
        padding: 60px;
    }

    .brand-top-heading h2 {
        color: #1B375C;
        text-align: center;
        font-size: 44px;

        font-weight: 700;

    }

    .brand-top-heading p {
        color: #1E1E1E;
        text-align: center;

        // font-size: 18px;
        margin-top: 20px;
        font-weight: 400;

    }

    .brand-section-bg {
        border-radius: 30px;
        background: #FFF;
        padding: 70px;
    }

    .brand-image-bg {
        border-radius: 15px;
        background: #ffffff;
        padding: 14px;
        -webkit-box-shadow: 3px 9px 37px -12px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 9px 37px -12px rgba(0, 0, 0, 0.75);
        box-shadow: 3px 9px 37px -12px rgba(0, 0, 0, 0.75);

    }

    .brand-last-head h3 {
        color: #1B375C;

        font-size: 30px;
        font-weight: 700;


    }

    .brand-last-head p {
        color: #1E1E1E;

        // font-size: 16px;
        margin-top: 10px;
        font-weight: 400;


    }

    .brand-last-head button {
        border-radius: 5px;
        background: #1B375C;
        box-shadow: 0px 10px 40px -12px rgba(30, 30, 30, 0.35);
        color: #FFF;
        border: none;
        width: 157px;
        height: 48px;
        margin-top: 10px;
        font-size: 18px;

        font-weight: 700;


    }


    /*===================== Research-section-start-css=============== */

    .research-heading h2 {
        color: #1B375C;
        text-align: center;
        font-size: 44px;


        font-weight: 700;


    }

    .research-heading p {
        color: #1E1E1E;
        text-align: center;
        // font-size: 18px;
        margin-top: 20px;
        font-weight: 400;



    }

    .research-section {
        padding: 60px;
    }

    .research-custom h3 {
        color: #1B375C;

        font-size: 44px;

        font-weight: 700;


    }

    .research-custom p {
        color: #1E1E1E;

        // font-size: 16px;
        margin-top: 15px;
        font-weight: 400;

    }

    .research-custom button {
        border-radius: 5px;
        background: #1B375C;
        box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
        color: #FFF;

        width: 157px;
        height: 48px;
        font-size: 18px;
        border: none;
        font-weight: 700;

    }

    .reseach-second-bg {
        border-radius: 15px;
        background: #ffffff;
        padding: 14px;
        border-radius: 25px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
        margin-top: 40px;

    }

    .reseach-second-bg img {
        width: 100%;
        object-fit: cover;
    }

    .group-brand {
        display: flex;
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .about-us-content-sec {
        .card-custom {

            margin-top: 36px;
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .about-us-content-sec {
        .first-head h2 {
            color: #1B375C;
            font-family: Barlow;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            margin-top: 20px;
            margin: 12px;
        }

        .first-head span {
            font-size: 22px;
        }

        .main-section {
            padding: 0px;
        }

        // .slider-card {
        //     flex-direction: column;
        //     align-items: center;

        // }

        .card-desgin {
            margin-top: 18px;
        }



        .banner-second {
            margin-top: 20px;
            padding: 18px;
        }

        .about-section {

            padding: 0px;
            margin-top: 15px;
        }

        .about-second-section p {
            padding: 0px;
            // font-size: 30px;
            text-align: justify;
            word-break: break-all;
            word-spacing: 0px;
        }

        .card-custom {
            margin-top: 30px;
        }

        .card-second-section h2 {
            margin-top: 25px;
            font-size: 30px;
        }

        .brand-section {

            padding: 0px;
            padding-bottom: 20px;
            .brand-last-head{
                padding-bottom: 20px;
            }
        }

        .brand-section-bg {

            padding: 0px;
        }

        .group-brand {
            display: block !important;
            margin-top: 24px;
            .group-brand-img{
                padding-left:20px;
            }
        }

        .research-section {
            padding: 0px;
            margin-top:20px;
        }

        .card-second-section {
            margin-left: 0px;
            p {
                color: #1E1E1E;
                // font-size: 16px;
                font-weight: 400;
            }
        }
      .brand-top-heading {
            p {
                color: #1E1E1E;
                text-align: center;
                // font-size: 16px;
                margin-top: 20px;
                font-weight: 400;
            }
        }
       .research-custom {
            h3 {
                color: #1B375C;
                font-size: 30px;
                font-weight: 700;
            }
        }
        .research-heading h2 {
            font-size: 30px;
            text-align: left;
            margin-top: 42px;
        }

        .about-us-heading {
            h2 {
                font-size: 30px;
                padding: 0px;
            }
           .get-in-touch-btn {
                border-radius: 5px;
                background: #1B375C;
                box-shadow: 0px 10px 40px -12px rgba(250, 202, 16, 0.35);
                color: #FFF;
                width: 157px;
                height: 48px;
                font-size: 18px;
                font-weight: 500;
                margin-left: 0px !important;
                outline: none;
                border: none;
            }
        }

        .about-us-heading p {
            // font-size: 16px;
            padding: 0px;
        }

        .first-head {
            margin-top: 25px;
        }

        .about-slider-heading h2 {
            font-size: 30px !important;
            text-align: left !important;
        }

        .about-second-section h2 {
            font-size: 30px;
            padding-left: 0px;
        }

        .brand-top-heading h2 {

            font-size: 30px;

            margin-top: 15px;
        }
    }
}