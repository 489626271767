.contact-us-section {
  /* Reset */
}
.contact-us-section .main-section {
  padding: 40px;
}
.contact-us-section .main-section .main-head {
  padding: 1px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.contact-us-section .main-section .main-head h2 {
  color: #1B375C;
  font-size: 22px;
  font-style: normal;
  margin-top: 20px;
  line-height: 54px;
  text-align: center;
  text-transform: capitalize;
}
.contact-us-section .first-head {
  margin-top: 110px;
}
.contact-us-section .first-head .display-flex-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.contact-us-section .first-head h2 {
  color: #1B375C;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%;
  margin-top: 152px;
}
.contact-us-section .first-head span {
  color: #0A65CC;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%;
}
.contact-us-section .banner-section {
  border-radius: 4px;
  background: #FFF;
  padding: 15px 15px 0px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  background: rgb(255, 255, 255);
  background: linear-gradient(94deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 14%, rgb(255, 255, 255) 32%, rgb(255, 255, 255) 45%, rgba(0, 147, 255, 0.0480567227) 78%, rgba(0, 147, 255, 0.0648634454) 100%);
}
.contact-us-section .banner-section .form-field-class {
  text-align: left;
}
.contact-us-section .banner-section .form-field-class label {
  text-align: left;
}
.contact-us-section .banner-second {
  padding: 55px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.contact-us-section .banner-head h2 {
  color: #303A42;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}
.contact-us-section .form-custom {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.04);
  padding: 15px;
}
.contact-us-section .form-custom2 {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.04);
  padding: 15px;
}
.contact-us-section .form-custom3 {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.04);
  padding: 15px 21px 45px 18px;
}
.contact-us-section .join-btn button {
  border-radius: 40px;
  background: #1B375C;
  color: #FFF;
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
  padding: 6px 105px 11px;
  border: 1px solid;
}
.contact-us-section .quote {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #000000;
  margin: 9px;
  padding: 13px;
  max-width: 100%;
  font-size: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 168px 0px rgba(0, 0, 0, 0.15);
}
.contact-us-section .second-box .quote-box .quote {
  position: relative;
}
.contact-us-section .second-box .quote-box .quote p {
  margin-bottom: 0px;
}
.contact-us-section .second-box .quote-box .quote p::after {
  border-color: transparent transparent transparent #fff;
  border-radius: 98% 2% 100% 0%/100% 0% 100% 0%;
  -o-border-image: none;
     border-image: none;
  border-style: solid;
  border-width: 22px;
  bottom: -21px;
  right: 0;
  content: " ";
  display: block;
  right: 40%;
  position: absolute;
  transform: rotate(358deg);
}
.contact-us-section .first-box .quote-box .quote {
  position: relative;
}
.contact-us-section .first-box .quote-box .quote p {
  margin-bottom: 0px;
}
.contact-us-section .first-box .quote-box .quote p::after {
  border-color: transparent #fff transparent transparent;
  border-radius: 98% 2% 100% 0%/100% 0% 100% 0%;
  -o-border-image: none;
     border-image: none;
  border-style: solid;
  border-width: 21px;
  bottom: -13px;
  content: " ";
  display: block;
  right: 59%;
  position: absolute;
  transform: rotate(116deg);
}
.contact-us-section .first-head blockquote {
  position: relative;
  top: 20px;
}

.call-card .card-area {
  height: 195px;
  padding: 15px;
  background: var(--White, #FFF);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
.call-card .card-area .textarea h4 {
  color: #1B375C;
  font-size: 25px;
  font-weight: 500;
}
.call-card .card-area .textarea h2 {
  color: var(--Black, #373737);
  font-size: 30px;
  font-weight: 600;
}
.call-card .card-area p {
  color: var(--gray-paragraph, #7B7B7B);
  font-size: 18px;
  margin: 0 0 5px 10px;
}
.call-card .card-area p span {
  color: #1B375C;
  font-size: 20px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .contact-us-section .first-head h2 {
    font-size: 18px;
  }
  .contact-us-section .first-head span {
    font-size: 18px;
  }
  .contact-us-section .form-custom2 {
    width: 265px !important;
  }
  .contact-us-section .form-custom3 {
    width: 265px !important;
  }
  .contact-us-section .join-btn button {
    font-size: 17px !important;
    padding: 6px 68px 10px !important;
  }
  .contact-us-section .main-section {
    padding: 0px;
  }
  .contact-us-section .quote {
    display: none !important;
  }
}
.call-card .card-area {
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .call-card .card-area {
    height: auto;
  }
  .call-card .card-area p {
    margin: 0;
  }
  .call-card .card-area > div {
    display: block !important;
  }
}/*# sourceMappingURL=ContactUs.css.map */