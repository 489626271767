.describe-the-job-sec {
  margin: 40px 0px;
}
.describe-the-job-sec .create-an-emp-inner {
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 40px;
}
.describe-the-job-sec .create-an-emp-inner .heading h2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.describe-the-job-sec .create-an-emp-inner .heading-inner h3 {
  font-size: 22px;
  font-weight: 600;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 40px;
  margin: 20px 0px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle {
  border: 1px solid rgb(27, 55, 92);
  color: #1B375C;
  border-radius: 40px;
  background-color: #fff;
  padding: 10px 0px;
  width: 100%;
  font-size: 15px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .job-type-btn .btn-circle:hover {
  background-color: #1B375C;
  color: #fff;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv label {
  font-weight: 600;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .textarea-combo-dv {
  border: 1px solid rgba(102, 102, 102, 0.3490196078);
  border-radius: 12px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 12px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea:focus-visible {
  border: none;
  outline: none;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea:focus {
  border: none;
  outline: none;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 12px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task img {
  margin-right: 10px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task span {
  margin-right: 10px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task .type-sub-task-a {
  text-decoration: none;
  color: inherit;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .three-icons img {
  margin: 0px 10px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 40px;
  padding: 10px 40px;
  text-decoration: none;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv .right-sd-btn .continue-btn span {
  margin-left: 10px;
}
.describe-the-job-sec .create-an-emp-inner .card-outer-dv1 {
  padding: 30px 40px;
}
.describe-the-job-sec .create-an-emp-inner .bottom-button-dv {
  margin: 40px 0px;
}
.describe-the-job-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn1 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  color: #1B375C;
  padding: 10px 50px;
}
.describe-the-job-sec .create-an-emp-inner .bottom-button-dv .bottom-btn .a-tag-btn2 {
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
  border-radius: 40px;
  background: rgb(27, 55, 92);
  color: #fff;
  padding: 10px 40px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .describe-the-job-sec .create-an-emp-inner {
    padding: 10px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv {
    padding: 10px 10px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .textarea-combo-dv {
    border: 1px solid rgba(102, 102, 102, 0.3490196078);
    border-radius: 12px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 12px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea:focus-visible {
    border: none;
    outline: none;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom textarea:focus {
    border: none;
    outline: none;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 12px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task img {
    margin-right: 10px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task span {
    margin-right: 10px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .type-sub-task .type-sub-task-a {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
  }
  .describe-the-job-sec .create-an-emp-inner .card-outer-dv .inner-input .text-area-custom .text-icons .three-icons img {
    margin: 0px 10px;
  }
  .describe-the-job-sec .create-an-emp-inner .bottom-button-dv {
    text-align: center;
    margin: 40px 0px;
  }
  .describe-the-job-sec .create-an-emp-inner .bottom-button-dv .bottom-btn {
    margin: 10px 0px;
    text-align: center;
  }
}/*# sourceMappingURL=DescribeTheJob.css.map */