@media only screen and (min-width: 768px) and (max-width: 992px) {
  .after-login-dash .profile-skill {
    justify-content: center;
  }
  .after-login-dash .resume .custm-resume {
    padding: 17px 20px;
  }
  .after-login-dash .apk .custm-apk {
    padding: 10px 10px;
  }
  .after-login-dash .apk .custm-apk .custm-qr {
    width: auto;
    height: auto;
  }
  .after-login-dash .apk .custm-apk .custm-download img {
    margin: 3px 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .after-login-dash .pro-complete .sec-rit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .after-login-dash .custm-fam-comp {
    border-radius: 10px;
    border: 1px solid #FFF5FF;
    background: #FFF;
    box-shadow: none;
    padding: 10px;
    height: 100%;
    margin: 0px !important;
  }
  .after-login-dash .custm-jobs .custm-right-sec {
    padding: 20px 10px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
    margin-top: 30px;
  }
  .after-login-dash .custm-jobs .custm-right-sec .latest-job-card {
    border-radius: 7px;
    background: #1B375C;
    padding: 20px;
    margin: 0px;
  }
  .after-login-dash .custm-jobs .custm-right-sec .latest-job-card-two {
    border-radius: 7px;
    background: #ffffff;
    padding: 20px;
    margin: 0 5px 0 5px;
    border-radius: 7px;
    box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  }
  .after-login-dash .profile-head > img {
    height: 100px;
  }
  .after-login-dash .profile-info .profile-img {
    position: unset;
  }
  .after-login-dash .profile-info {
    display: block !important;
  }
  .after-login-dash .sec-lft .profile-skill {
    justify-content: center;
  }
  .after-login-dash .prof-main {
    justify-content: center !important;
  }
  .after-login-dash .performance .rec-actn {
    margin-bottom: 10px;
  }
  .after-login-dash .resume .custm-resume {
    margin-bottom: 10px;
  }
  .after-login-dash .custm-jobs .recruiters {
    display: block !important;
  }
  .after-login-dash .latest-job-card .job-role {
    display: block !important;
  }
  .after-login-dash .latest-job-card-two .job-role {
    display: block !important;
  }
  .after-login-dash .recruiters .right-sec .loc {
    display: block !important;
  }
}
@media (max-width: 320px) {
  .after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-menu-btn {
    right: 0;
  }
}/*# sourceMappingURL=AfterResponsive.css.map */