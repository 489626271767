@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .sidebar-menu-parent {
        .sidebar-menu {
            .sidebar-link {
                height: 340px;
                overflow-y: scroll;
            }

            .sidebar-unlist {
                li {
                    &:last-child {
                        margin-bottom: 130px;
                    }
                }
            }
        }
    }

    .after-login-sec {
        .main-header {
            .head-sec-rit {
                .head-right-user {
                    .user-name-add {
                        h3{
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                        p {
                            color: #fff;
                            margin-bottom: 0;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .after-login-sec {
        .main-header {
            .head-sec-rit {
                .head-right-user {
                    .user-name-add {
                        p {
                            color: #fff;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .header-second {
            display: block;

            .menu-tab {
                margin-right: 0px;
            }

            .navbar-ul-custom {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }

        .modal-body-content {
            padding: 9px 0px;
        }

        .sidebar-menu-parent {
            .sidebar-menu {
                .sidebar-link {
                    height: 340px;
                    overflow-y: scroll;
                }
            }
        }
    }
}