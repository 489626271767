.blog-list {
    .blog-head {
        border-radius: 4px;
        background: #F4FAFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;
        padding: 15px 0;

        h1 {
            color: #1B375C;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 700;
        }
    }

    .blog-info {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        margin-bottom: 80px;

        .blog-card {
            .category {
                img {
                    width: 16px;
                    height: 16px;
                }

                h5 {
                    margin: 0 12px;
                    padding: 5px 35px;
                    border-radius: 2px;
                    background: #D3E7F5;
                    color: var(--Text, #151875);
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .date {
                img {
                    width: 16px;
                    height: 16px;
                }

                h5 {
                    margin: 0 12px;
                    padding: 5px 20px;
                    border-radius: 2px;
                    background: #FFE7F9;
                    color: var(--Text, #151875);
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .blog-title {
                h2 {
                    text-align: left;
                    margin: unset;
                    color: #1B375C;
                    font-family: Barlow;
                    font-size: 28px;
                    font-weight: 700;
                }
            }


            .blog-para {
                p {
                    text-align: left;
                    color: var(--sub-text-color, #8A8FB9);
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .blog-para2 {
                p {
                    text-align: left;
                    color: #8A8FB9;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .blog-para3 {
                background: #FAFAFB;
                padding: 0 10px;
                border-left: 2px solid #FC45A0;

                p {
                    text-align: left;
                    color: #969CB4;
                    font-size: 18px;
                    font-style: italic;
                    font-weight: 600;
                    line-height: 40px;
                }
            }

            .read-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #1B375C;
                    font-size: 18px;
                    font-weight: 600;
                    text-decoration: none;
                }
            }

        }

        .blog-media {
            .media-box1 {
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .textarea {
                p {
                    text-align: left;
                    color: #8A8FB9;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25.6px;
                }
            }
        }
    }
}