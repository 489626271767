.company-details-form {
    .form-head-dv {
        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
        border-radius: 40px;
        padding: 30px;
        margin: 60px 0px;
        .check-circle-parent{
            .check-circle-relative{
                position: relative;
                .greencheck-circle-absolute{
                    position: absolute;
                    top: 45%;
                    right: 1%;
                    transform: translate(-1%, -45%);
                }
            }

        }
        .next-button{
            margin-top: 30px;
          .next-btn{
            box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.5);
            background: rgb(27, 55, 92);
            color: #fff;
            border-radius: 32px;
            padding: 10px 125px;
            border: none;
            margin-top: 4rem;
            text-decoration: none;
           }
        }
        .cd-heading {
            text-align: left;
            h2{
                font-size: 28px;
            }
        }

        .cd-form-f {
            width: 100%;
            text-align: left;

            label {
                margin-bottom: 5px;
            }

            input {
                border: 1px solid rgba(173, 173, 173, 1);
                width: 100%;
                border-radius: 9px;
                padding: 7px 15px 7px 15px;
            }
        }

        .cd-form-f-btn {
            input {
                background: linear-gradient(0deg, #0A65CC, #0A65CC),
                    linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
                border: 1px solid rgba(255, 255, 255, 0.35);
                color: #fff;
                padding: 8px 15px 8px 15px;
            }
            margin-top: 26px;
        }
    }

}