.subscription-plan-section {
    margin: 40px 0px 30px 0px;
    .main-head {
        width: 100%;
        height: 65px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #F4FAFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), 0px 4px 20px 0px rgba(29, 95, 181, 0.20) inset;

    }

    .main-head{
        h2 {
            color: #1B375C;
            font-size: 22px;
            font-weight: 700;
            line-height: 50px;
            /* 100% */
            text-transform: capitalize;
            text-align: center;
        }
    }

    .first-card{
        p {
            display: flex;
            padding: 6.386px 14.901px;
            align-items: center;
            gap: 8.643px;
            border-radius: 28.737px;
            background: linear-gradient(180deg, #D7D7D7 0%, #BBB 100%);
            color: #000;
            text-align: center;

            font-size: 12.772px;
            font-weight: 600;
    
            width: 170px
        }
    }

    .second-card{
        p {
            display: flex;
            padding: 6.386px 14.901px;
            align-items: center;
            gap: 8.643px;
            border-radius: 28.737px;
            background: #FFED47;
            color: #000;
            text-align: center;

            font-size: 12.772px;
            font-weight: 600;
    
            width: 170px
        }
    }

    .third-card{
        p {
            padding: 6.386px 14.901px;
            border-radius: 28.737px;
            background: #FFCFD2;
            color: #000;
            text-align: center;

            font-size: 12.772px;
            font-weight: 600;
    
            width: 176px
        }
    }

    .Subscrip-card {
        flex-shrink: 0;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }


    .card-custom {
        padding: 30px;
    }

    .card-1 {
        padding: 20.994px;
        border-radius: 21.287px;
        border: 1px solid rgba(27, 55, 92, 0.50);
        background: #FFF;
    }

    .para {
        color: #000;
        font-size: 12.772px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
    }

    .border-bottom {
        width: 291px;
        height: 2px;
        background: #000;
    }

    li {
        list-style: none;
        padding: 10px 5px 0px;
    }

    .last-head{
        margin: 20px 0px;
        h2 {
            color: #1F1F1F;
            text-align: center;
            font-size: 38.316px;
            font-weight: 700;
            span{
                font-size: 16px;
                color: #ccc;
            }
        }
    }

    .last-hea{
        h2{
            span {
                color: #AEAEAE;
    
                font-size: 17.029px;

                font-weight: 600;
        
            }
        }
    }

    .last-btn {
        border-radius: 85.147px;
        background: #1B375C;
        color: #FFF;
        text-align: center;
        font-size: 12.772px;
        font-style: normal;
        font-weight: 600;

        width: 274px;
        padding: 10.965px 0px;
        border: none;
        outline: none;
    }

    .list-1 ul {
        padding: 0px;
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .subscription-plan-section {
        .card-1 {
            display: block;
            margin-top: 18px;

        }

        .border-bottom {
            width: 100%;
        }

        .last-btn {
            width: 100%;
        }
        .third-card, .second-card, .first-card {
            p {
                width: 100%;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:900px) {
    .subscription-plan-section {
        .card-1 {
            display: block;
            margin-top: 18px;

        }

        .border-bottom {
            width: 100%;
        }

        .last-btn {
            width: 100%;
        }
        .third-card, .second-card, .first-card {
            p {
                width: 100%;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
            }
        }
    }
}