.post-a-joboverview-sec {
    margin: 40px 0px;

    .create-an-emp-inner {
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
        box-shadow: -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
        padding: 40px;

        .heading {
            h2 {
                font-weight: 600;
                margin-bottom: 8px;
            }
        }

        .heading-inner {
            h3 {
                font-size: 22px;
                font-weight: 600;
            }
        }

        .overview-top-head {
            h4 {
                small {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }

        .overview-button {
            .see-full-descriptions {
                border: 1px solid #1B375C;
                color: #1B375C;
                text-decoration: none;
                border-radius: 40px;
                padding: 10px 30px;
            }
        }

        .setting-row-parent {
            .setting-row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;
            }
            .inpt-data-rpint{
                .int-tag{
                    border: none;
                    font-size: 15px;
                    color: #ccc;
                    &:focus-visible{
                        border: none;
                        outline: none;
                    }
                }
            }
        }






        .bottom-button-dv {
            margin: 40px 0px;

            .bottom-btn {
                .a-tag-btn1 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    color: linear-gradient(0deg, #1B375C, #1B375C);
                    padding: 10px 50px;
                }

                .a-tag-btn2 {
                    border: 1px solid rgba(27, 55, 92, 1);
                    text-decoration: none;
                    border-radius: 40px;
                    background: rgba(27, 55, 92, 1);
                    color: #fff;
                    padding: 10px 40px;
                }
            }
        }

    }
}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:767px) {
    .post-a-joboverview-sec {
        .create-an-emp-inner {
            padding: 10px;

            .bottom-button-dv {
                text-align: center;
                margin: 40px 0px;

                .bottom-btn {
                    margin: 10px 0px;
                    text-align: center;
                }
            }
        }

    }
}