// ====================Personal Details Start=======================
.personal-detail {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }

            .vodeo-btn {
                a {
                    padding: 8px 17px;
                    text-decoration: none;
                    border-radius: 10px;
                    border: 1px solid #0A65CC;
                    background: #0A65CC;
                    color: #FFF;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }



            .cv-upload-input-cls {
                cursor: pointer;
            }

            .cv-upload-input-cus {

                visibility: hidden;
            }

            // input {
            //     display: flex;
            //     padding: 15px 27px 17px 24px;
            //     justify-content: center;
            //     align-items: center;
            //     border-radius: 12px;
            //     border: 1px solid rgba(102, 102, 102, 0.35);

            //     &::placeholder {
            //         color: rgba(102, 102, 102, 0.60);
            //     }
            // }

            .verify-btn {
                color: #FFF;
                display: inline-flex;
                padding: 7px 40px;
                border-radius: 12px;
                border: 1px solid rgba(255, 255, 255, 0.35);
                background: #0A65CC;
                text-decoration: none;
            }

            select {
                display: flex;
                padding: 8px 40px;
                justify-content: center;
                align-items: center;
                color: rgba(102, 102, 102, 0.60);
            }

            .cv-upload {
                border-radius: 12px;
                border: 1px dashed #0A65CC;
                padding: 15px;
                
                .resume {
                    h4 {
                        color: #0A65CC;
                        
                        font-size: 22px;
                        font-weight: 700;
                        text-align: left;
                    }

                    p {
                        margin: unset;
                        color: rgba(102, 102, 102, 0.60);
                        
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .upload img {
                    width: 30px;
                    height: 30px;
                }
            }

            .cus-verify {
                position: relative;

                img {
                    position: absolute;
                    right: 25px;
                    top: 40px;
                }
            }

            .cus-pass-section {
                position: relative;

                span {
                    position: absolute;
                    right: 25px;
                    top: 43px;
                }
            }

            h6 {
                span {
                    a {
                        color: #0A65CC;
                        
                        font-size: 16px;
                        font-weight: 700;
                        text-decoration: none;
                    }
                }
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }


}

// ====================Personal Details End=======================


// ====================Educational Details Start=======================
.educational-detail {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        .edu-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            .upload-docu-icn-cls {
                
                .upload-docu-icon {
                    position: relative;

                    input {
                        padding: unset;
                        border-radius: unset;
                        border: unset;
                        position: absolute;
                        visibility: hidden;
                    }

                    img {
                        position: absolute;
                        top: 20px;
                        right: 13px;
                    }

                    .upload-docu-label {
                        padding: 15px 27px 17px 24px;
                        border-radius: 12px;
                        border: 1px solid rgba(102, 102, 102, 0.35);
                        color: #66666659;
                        cursor: pointer;
                    }
                    .label-img{
                        display: block;
                    }
                }
            }


            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);

                &::placeholder {
                    color: rgba(102, 102, 102, 0.60);
                }
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
                color: rgba(102, 102, 102, 0.60);
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 16px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
                border: 1px solid #1B375C;
            }
        }
    }

}

// ====================Educational Details End=======================


// ====================Professional Details Start=======================

.professional-detail {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        .edu-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);
                // color: rgba(102, 102, 102, 0.60);

                &::placeholder {
                    color: rgba(102, 102, 102, 0.60);
                }
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
                color: rgba(102, 102, 102, 0.60);
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 16px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;
            }
        }
    }
}

// ====================Professional Details End=======================



// ====================Project Details Start=======================
.project-detail {

    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .project-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 700;
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);

                &::placeholder {
                    color: rgba(102, 102, 102, 0.60);
                }
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid #1B375C;

            }
        }
    }

}

// ====================Project Details End=======================


// ====================Key Skills Start=======================
.key-skills {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .project-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 700;
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
                color: rgba(102, 102, 102, 0.60);
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                border: 1px solid #1B375C;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

// ====================Key Skills End=======================



// ====================Certification Start=======================
.certification {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .project-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 700;
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);

                &::placeholder {
                    color: rgba(102, 102, 102, 0.60);
                }
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
            }

            .skip {
                padding: 10px 40px;
                border-radius: 40px;
                border: 1px solid #1B375C;
                background: #FFF;
                text-decoration: none;
                color: #1B375C;
                text-align: center;
                font-size: 16px;
                font-weight: 500;

            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                border: 1px solid #1B375C;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

// ====================Certifiction End=======================



// ====================Social Media Start=======================
.social-media {
    padding: 50px 0;

    .stepper-area {
        height: 100%;
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .form-area {
        padding: 40px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;

        .profile-back-btn {
            .back-btn {
                a {
                    background-color: unset;
                    border: unset;
                    color: #0A65CC;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

        .profile-edit-btn {
            display: inline-grid;
            align-content: space-between;

            .edit-btn {
                a {
                    padding: 8px 45px;
                    border-radius: 50px;
                    border: 1px solid rgba(10, 101, 204, 0.50);
                    background-color: unset;
                    text-decoration: none;
                }
            }
        }

        .project-title {
            h1 {
                color: #333;
                text-align: center;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 700;
            }
        }

        .cust-add-btn {
            a {
                padding: 8px 10px;
                border-radius: 10px;
                border: 1px solid rgba(10, 101, 204, 0.50);
                color: #0A65CC;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                background-color: unset;
            }
        }

        form {
            label {
                text-align: left;

                span {
                    color: red;
                }
            }

            input {
                padding: 15px 27px 17px 24px;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);
            }

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 27px 17px 24px;
            }

            .social-icons {

                .social-lft {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid rgba(52, 67, 75, 0.15);
                    padding: 2px;
                }

                p {
                    color: #8D9CA5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    margin: unset;
                }

                .check {
                    width: 16px;
                    height: 16px;
                    margin: 0 20px;
                    margin-left: auto;
                }

                img {
                    width: 45px;
                    height: 45px;
                }
            }

            .next {
                padding: 10px 40px;
                border-radius: 40px;
                background: #1B375C;
                font-size: 16px;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-weight: 500;

            }
        }
    }
}

// ====================Social Media End=======================