.cv-for-emp-content-sec {
    .bg-form {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        padding: 0px 30px;
        margin: 20px 0px;
        .arror-icon {
            padding: 20px;
            text-align: left;
            a {
                text-decoration: none;
        
            }
        }
        .first-head {
            padding: 20px;
            text-align: left;
            h2 {
                font-size: 24px;
            }
        }
   
        .form-banner {
            padding: 0px 20px;
            .cv-for-employer-input{
                visibility: hidden;
            }
            .cv-upload {
                border-radius: 12px;
                border: 1px dashed #0A65CC;
                padding: 15px;
                cursor: pointer;
            }
        
            .resume {
                p {
                    margin: unset;
                    color: rgba(102, 102, 102, 0.60);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                h4 {
                    color: #0A65CC;
                    font-size: 22px;
                    font-weight: 700;
                    text-align: left;
                }
            }
        
            .upload-para {
                p{
                    text-align: left;
                    span {
                        margin-left: 5px;
                        a {
                            color: #0A65CC;
                            font-size: 16px;
                            font-weight: 700;
                            text-decoration: none;
                        }
                    }
                }
                
            }
        
            .upload-btn {
                text-align: center;
                padding: 40px 0px;
                .upload-btn-a {
                    border-radius: 40px;
                    background: #1B375C;
                    width: 300px;
                    padding: 10px 40px;
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    outline: none;
                }
            }
        
        }
    

    }






}

@media only screen and (min-width:200px) and (max-width:767px){
    .cv-for-emp-content-sec{
       .bg-form {
            padding: 0px 10px;
            .bg-form{
                .first-head {
                    h2 {
                        font-size: 17px;
                    }
                }
            }
            .form-banner{
                
                .resume{
                    h4 {
                        font-size: 14px;
                    }
                    p {
                        font-size: 10px;
                        text-align: left;
                    }
                }
                .upload-para{
                    p {
                        text-align: center;
                        span {
                            margin-left: 5px;
                            display: block;
                        }
                    }
                }
                .upload-btn {
                    text-align: center;
                    padding: 0px 0px 30px 0px;
                    .upload-btn-a {
                        width: 190px !important;
                    }
                }
            }
        }
        .upload{
            img {
                width: 18px;
            }
        }
        .list-section{
            ul {
                flex-direction: column;
            }
        }

        .heading a {
            margin-left: 50px;
        }

        .form-btn2 {
            width: 190px !important;
        }

        .bg-second {
            padding: 0px !important;
        }

        .ptn {
            margin: 20px;
        }

        .para span a {
            margin: 37px;
        }

        .form-banner {
            padding: 0px 9px !important;
        }

        .top-btn {
            margin-top: 0px !important;
        }

        .form-btn {
            padding: 2px 24px 6px 28px !important;
        }

        .form-btn2 {
            margin-top: 10px !important;
        }


    }
}