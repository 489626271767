.sponsor .head-area h2 {
  color: #1B375C;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}
.sponsor .custm-bud-text h4 {
  text-align: left;
  color: #1B375C;
  font-size: 28px;
  font-weight: 700;
  padding: 50px 0 0 0;
}
.sponsor .custm-bud-text p {
  text-align: left;
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
  padding: 0 60px 0 0;
}
.sponsor .custm-bud-img img {
  width: 400px;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  border-radius: 25px;
  border: 10px solid #fff;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .sponsor {
    padding-top: 20px !important;
  }
  .sponsor .head-area {
    margin-bottom: 0 !important;
  }
  .sponsor .head-area h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
  }
  .sponsor .custm-bud-img img {
    width: auto;
    height: auto;
  }
  .sponsor .custm-bud-text h4 {
    padding: 20px 0 0 0;
    font-size: 18px;
  }
  .sponsor .custm-bud-text p {
    padding: 0px;
    font-size: 15px;
  }
}/*# sourceMappingURL=sponsor.css.map */