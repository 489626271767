.news-header .news-heading {
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.news-header .news-heading h1 {
  color: #1B375C;
  font-family: Barlow;
  font-size: 22px;
  font-weight: 700;
  margin: unset;
}

.news-banner .custm-banner-area {
  background-image: url(../../../../../public/assets/images/news/bann.png);
  background-size: cover;
  background-position: 100%;
  height: 450px;
}
.news-banner .custm-banner-area .banner-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.news-banner .custm-banner-area .holy-btn {
  padding: 0 0 0 20px;
}
.news-banner .custm-banner-area .holy-btn a {
  padding: 8px 10px;
  background: #08F;
  color: #FFF;
  font-size: 14px;
  text-decoration: none;
}
.news-banner .custm-banner-area .banner-text {
  padding: 20px;
}
.news-banner .custm-banner-area .banner-text h2 {
  color: #FFF;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 30px 0;
}
.news-banner .custm-banner-area .banner-text h3 {
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  line-height: 125.6%;
}
.news-banner .banner-cards {
  padding: unset;
}
.news-banner .banner-cards .card-img {
  position: relative;
}
.news-banner .banner-cards .card-img a {
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 2px 5px;
  text-decoration: none;
  color: #FFF;
  font-size: 8px;
}
.news-banner .banner-cards .c1 a {
  background: #08F;
}
.news-banner .banner-cards .c2 a {
  background: #49CFE8;
}
.news-banner .banner-cards .c3 a {
  background: #2ECC71;
}
.news-banner .banner-cards ul {
  padding: unset;
  margin: 0 0 5px 0;
}
.news-banner .banner-cards ul li {
  list-style: none;
}
.news-banner .banner-cards ul li:nth-child(1) {
  color: #393939;
  font-size: 10px;
}
.news-banner .banner-cards ul li:nth-child(2) {
  color: rgba(57, 57, 57, 0.6);
  font-size: 10px;
}
.news-banner .banner-cards h3 {
  color: #393939;
  font-size: 15px;
  font-weight: 500;
}

.entertainment h1 {
  color: #F65050;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.entertainment .col-lg-9 hr {
  margin: unset;
  width: 90%;
  height: 1px;
  background-color: #000000;
  border: none;
}
.entertainment .news-display img {
  margin: 0 0 20px 0;
}
.entertainment .news-display ul {
  padding: unset;
  margin: 0 0 5px 0;
}
.entertainment .news-display ul li {
  list-style: none;
}
.entertainment .news-display ul li:nth-child(1) {
  color: #393939;
  font-size: 10px;
}
.entertainment .news-display ul li:nth-child(2) {
  color: rgba(57, 57, 57, 0.6);
  font-size: 10px;
}
.entertainment .news-display h3 {
  color: #393939;
  font-size: 18px;
  font-weight: 500;
}
.entertainment .news-display p {
  color: rgba(57, 57, 57, 0.6);
  font-size: 14px;
  line-height: 111.1%;
}
.entertainment .news-list ul {
  padding: unset;
  margin: 0 0 5px 0;
}
.entertainment .news-list ul li {
  list-style: none;
}
.entertainment .news-list ul li:nth-child(1) {
  color: #393939;
  font-size: 10px;
}
.entertainment .news-list ul li:nth-child(2) {
  color: rgba(57, 57, 57, 0.6);
  font-size: 10px;
}
.entertainment .news-list h3 {
  color: #393939;
  font-size: 15px;
  font-weight: 500;
}
.entertainment .nav-tabs .nav-link {
  border: unset;
  color: #000000 !important;
  font-size: 14px;
  padding: 0 20px 15px 0;
  border-bottom: 1px solid #000000;
}
.entertainment .nav-tabs .nav-link.active {
  color: #F65050 !important;
  font-size: 14px;
  border-bottom: 1px solid #F65050;
}

.sports h1 {
  color: #F65050;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.sports .col-lg-9 hr {
  margin: unset;
  width: 90%;
  height: 1px;
  background-color: #000000;
  border: none;
}
.sports .news-display img {
  margin: 0 0 20px 0;
}
.sports .news-display ul {
  padding: unset;
  margin: 0 0 5px 0;
}
.sports .news-display ul li {
  list-style: none;
}
.sports .news-display ul li:nth-child(1) {
  color: #393939;
  font-size: 10px;
}
.sports .news-display ul li:nth-child(2) {
  color: rgba(57, 57, 57, 0.6);
  font-size: 10px;
}
.sports .news-display h3 {
  color: #393939;
  font-size: 18px;
  font-weight: 500;
}
.sports .news-display p {
  color: rgba(57, 57, 57, 0.6);
  font-size: 14px;
  line-height: 111.1%;
}
.sports .news-list ul {
  padding: unset;
  margin: 0 0 5px 0;
}
.sports .news-list ul li {
  list-style: none;
}
.sports .news-list ul li:nth-child(1) {
  color: #393939;
  font-size: 10px;
}
.sports .news-list ul li:nth-child(2) {
  color: rgba(57, 57, 57, 0.6);
  font-size: 10px;
}
.sports .news-list h3 {
  color: #393939;
  font-size: 15px;
  font-weight: 500;
}
.sports .title h3 {
  color: #F65050;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sports .social-box {
  background: #4281FF;
  width: 130px;
  height: 40px;
}
.sports .social-box img {
  width: 16px;
  height: 16px;
}
.sports .social-box .followers h4,
.sports .social-box .followers h5 {
  margin: 5px;
}
.sports .social-box .followers h4 {
  color: #FFF;
  font-size: 8px;
}
.sports .social-box .followers h5 {
  color: #FFF;
  font-size: 8px;
}
.sports .cus-ins {
  background: #C23785 !important;
}
.sports .cus-in {
  background: #087DD8 !important;
}
.sports .cus-tw {
  background: #42C0F5 !important;
}/*# sourceMappingURL=news.css.map */