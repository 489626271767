.assessment-score-sec {

    /* Box shadow outer */
    .boxshadow_head {
        box-shadow: 0px 20px 60px 0px #F1F4F880;

        box-shadow: -4px -4px 20px 0px #1B375C33 inset;
        border: 1px solid #F1F4F880;


    }

    /*  For blue clickable back */
    .cust_back {
        margin-left: 1px;
    }

    .heading_color {
        // color: #F91616;
        color: green;
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 10px;

    }

    .cust_performance {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        flex-wrap: nowrap;
        margin-bottom: 10px;
    }

    /*   Profile performance    */
    .heading_style {
        color: #1B375C;
        font-size: 20px;
        font-weight: 700;
        margin-right: 1px;
    }

    /*  Number of passing score  */
    .heading_style1 {
        color: #1B375C;
        font-size: 20px;
        font-weight: 700;
        margin-left: 1px;
    }

    .boxshadow_inner {
        border-radius: 4px;
        border: 1px solid #F1F4F880;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
        margin: 5px;
    }

    /*  Inner box contents starts here  */
    .circle {

        width: 150px;
        height: 150px;
        padding: 0px;
        background: #fff;

        color: #000;
        text-align: center;
        font: 32px Arial, sans-serif;
        /* shadow */
        border-radius: 100px;
        border: 1px solid #1B375C;
        background: var(--gray-white, #FFF);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25), -1px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;

    }

    .circle_content {
        margin-left: 17%;
        margin-right: 20px;
        margin-top: 46px;
        font-size: 44px;
    }

    .cust_gray {
        color: gray;
    }

    .color {
        background-color: goldenrod;
    }

    /*  a tag for  View all question  */
    a {
        color: #0A65CC;
        font-size: 16px;
    }

    .text_sujjection {
        color: #1B375C;
        font-size: 24px;
        font-weight: 500;
    }

    .button_width {
        width: 200px;

    }

    .button_width1 {
        width: 200px;
        background-color: #1B375C;
    }

    .boxshadow_progressbar {
        box-shadow: 2.477119207382202px 2.477119207382202px 18.578393936157227px 0px #A6ABBD40;


        box-shadow: -1.238559603691101px -1.238559603691101px 16.101274490356445px 0px #FAFBFF;

    }

    .cust_img {
        max-width: 120px;
        max-height: 120px;
    }




}

/* Media querry sm  */
@media screen and ((min-width:320px) and (max-width:576px)) {
    .assessment-score-sec {
        .cust_img {
            max-width: 85px;
            max-height: 85px;
        }

        .cust_back {
            margin-top: 0px;
            font-size: 18px;
            margin-left: -27px;
        }

        .heading_color {
            font-size: 18px;
        }

        .heading_style {
            font-size: 15px;
            padding: 8px;
            margin-right: 0px;
        }

        .heading_style1 {
            font-size: 15px;
            padding: 2px;
            margin-left: 21px;

        }

        .circle {
            width: 140px;
            height: 140px;
            text-align: center;
            margin: 0 auto;

        }

        .boxshadow_inner {
            margin: 0px;
        }

        .text_sujjection {
            font-size: 18px;
        }
    }

}