.build-resume-sec {
  /*===================== banner-section-start-css ======================*/
  /*================ Build your resume-section-start-css ================*/
  /*================ Snapshot-section-start-css ==============*/
  /*=================== Template-section-start-css ==========================*/
  /*==================== about-section-start-css ====================*/
  /*================== benefits-section-start-css ================*/
  /*============== Testimonials From-section-start-css =================*/
}
.build-resume-sec .bulid-heading h3 {
  color: #000;
  font-size: 36px;
  font-weight: 600;
}
.build-resume-sec .bulid-heading span {
  color: #1B375C;
  font-size: 36px;
  font-weight: 600;
}
.build-resume-sec .bulid-para p {
  color: #8d7e7e;
  font-weight: 400;
}
.build-resume-sec .bulid-btn {
  text-align: center;
}
.build-resume-sec .bulid-btn button {
  border-radius: 29px;
  padding: 14px 60px;
  background: #1B375C;
  color: #FFF;
  margin-top: 25px;
  font-weight: 600;
  border: none;
  outline: none;
}
.build-resume-sec .build-resume-banner {
  position: relative;
}
.build-resume-sec .build-resume-banner .icons-float-banner .position-third-section1 {
  position: absolute;
  bottom: 0;
  left: 42%;
  z-index: -1;
}
.build-resume-sec .build-resume-banner .icons-float-banner .position-section1 {
  position: absolute;
  top: 0%;
  left: 10%;
  z-index: -1;
}
.build-resume-sec .build-resume-banner .icons-float-banner .position-second-section1 {
  position: absolute;
  top: 14%;
  right: 9%;
  z-index: -1;
}
.build-resume-sec .bulid-resume-section {
  position: relative;
}
.build-resume-sec .position-section {
  position: absolute;
  top: 8%;
  right: 6%;
}
.build-resume-sec .position-third-section {
  position: absolute;
  top: 42%;
  left: 42%;
}
.build-resume-sec .position-second-section {
  position: absolute;
  top: 63%;
  right: 17%;
}
.build-resume-sec .bulid-resume-heading h3 {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.build-resume-sec .bulid-resume-heading span {
  color: #0A65CC;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.build-resume-sec .resume-heading h5 {
  color: #0A65CC;
  font-weight: 700;
  margin-bottom: 0px;
}
.build-resume-sec .resume-heading span {
  color: #0A65CC;
  font-size: 50px;
  font-weight: 700;
}
.build-resume-sec .resume-heading p {
  color: #0A65CC;
  font-weight: 400;
}
.build-resume-sec .Snapshot-section {
  background-image: url("../../../../../public/assets/build-resume/third-secyion-bg.png");
  background-size: cover;
  background-position: 100%;
  padding: 50px;
}
.build-resume-sec .Snapshot-right-list li {
  color: #1B375C;
  letter-spacing: 0.232px;
  font-size: 23px;
  padding: 8px;
  font-weight: 700;
}
.build-resume-sec .Snapshot-right-list li span {
  color: #1B375C;
  font-size: 23px;
  font-weight: 500;
}
.build-resume-sec .Snapshot-para p {
  color: #000;
  font-weight: 400;
  letter-spacing: 2px;
}
.build-resume-sec .Snapshot-para h4 {
  color: #000;
  font-size: 20px;
  font-weight: 300;
  margin-top: 25px;
}
.build-resume-sec .Snapshot-top-heading h3 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
}
.build-resume-sec .Snapshot-btn button {
  border-radius: 22px;
  padding: 11.135px 46px;
  background: #1B375C;
  color: #FFF;
  border: none;
  font-size: 14px;
  font-weight: 700;
}
.build-resume-sec .Snapshot-right-para h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
}
.build-resume-sec .Snapshot-right-para p {
  color: #000;
  font-weight: 400;
}
.build-resume-sec .border-bottom {
  border: 1px solid #000;
  height: 60px;
}
.build-resume-sec .template-bg {
  background: #f7f7f7;
  padding: 37px;
}
.build-resume-sec .template-top-heading h3 {
  color: #1D1D1D;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
}
.build-resume-sec .template-top-heading h5 {
  color: #1B375C;
  margin-top: 10px;
  font-size: 23px;
  font-weight: 600;
}
.build-resume-sec .template-top-heading p {
  color: #000;
  margin-top: 20px;
  text-align: justify;
}
.build-resume-sec .template-right-side {
  display: flex;
  justify-content: flex-end;
}
.build-resume-sec .about-top-heading h3 {
  color: #1D1D1D;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.build-resume-sec .about-para p {
  color: #000;
  padding: 20px;
  text-align: justify;
}
.build-resume-sec .about-btn button {
  border-radius: 20px;
  padding: 10px 43px;
  background: #1B375C;
  color: #FFF;
  font-size: 13px;
  font-weight: 600;
  border: none;
  margin-left: 22px;
}
.build-resume-sec .ab-right-img {
  position: relative;
}
.build-resume-sec .ab-right-img .right-img-ch-dv img {
  z-index: 2;
}
.build-resume-sec .ab-right-img .right-img-ch-dv:before {
  content: url("../../../../../public/assets/build-resume/about-us-top.png");
  position: absolute;
  top: -25px;
  left: 10px;
  z-index: -1;
}
.build-resume-sec .ab-right-img .right-img-ch-dv:after {
  content: url("../../../../../public/assets/build-resume/about-us-bottom.png");
  position: absolute;
  bottom: -25px;
  right: -30px;
  z-index: -1;
}
.build-resume-sec .benefits-top-heading h3 {
  color: #1D1D1D;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.build-resume-sec .benefits-top-heading h5 {
  color: #1B375C;
  text-align: center;
  font-size: 22px;
  margin-top: 15px;
  font-weight: 600;
}
.build-resume-sec .benefits-card-section {
  display: flex;
  align-items: flex-end;
}
.build-resume-sec .benefits-card-section h5 {
  font-weight: 600;
}
.build-resume-sec .benefits-card-section .benefits-card-child {
  width: 400px;
}
.build-resume-sec .benefits-card-section .benefits-card-child img {
  width: 200px;
}
.build-resume-sec .benefits-card-section p {
  margin-bottom: 0px;
}
.build-resume-sec .Testimonials-section {
  background-image: url("../../../../../public/assets/build-resume/footer-testimonial.png");
  background-size: cover;
  padding: 80px;
}
.build-resume-sec .Testimonials-card-para {
  position: relative;
}
.build-resume-sec .Testimonials-card-para p {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
  text-align: justify;
}
.build-resume-sec .Testimonials-card-para p:before {
  position: absolute;
  content: open-quote;
  left: -15px;
  top: -15px;
  font-size: 40px;
}
.build-resume-sec .Testimonials-card-para p:after {
  position: absolute;
  content: close-quote;
  /* right: 0px; */
  bottom: -10px;
  font-size: 40px;
}
.build-resume-sec .Testimonials-card-para span {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}
.build-resume-sec .card-custom {
  padding: 25px;
  border-radius: 7.47px;
  border: none;
  background: #FFF;
  height: 260px;
  box-shadow: 0px 3.71161px 3.71161px 0px rgba(0, 0, 0, 0.25);
}
.build-resume-sec .card-custom-second {
  padding: 21px;
  border-radius: 7.47px;
  border: none;
  background: #FFF;
  box-shadow: 0px 3.71161px 3.71161px 0px rgba(0, 0, 0, 0.25);
  height: 260px;
}
.build-resume-sec .Testimonials-heading h5 {
  color: #000;
  font-size: 23px;
  font-weight: 700;
}
.build-resume-sec .Testimonials-heading span {
  color: #0A65CC;
  font-size: 15px;
  font-weight: 700;
}
.build-resume-sec .Testimonials-top-heading h3 {
  font-size: 46px;
  font-weight: 700;
}
.build-resume-sec .Testimonials-top-heading p {
  color: #0A65CC;
  font-weight: 400;
}
.build-resume-sec .Testimonials-heading p {
  color: #0A65CC;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .build-resume-sec .ab-right-img {
    text-align: center;
  }
  .build-resume-sec .ab-right-img .right-img-ch-dv:after {
    right: 0px;
    z-index: -1;
  }
  .build-resume-sec .ab-right-img .right-img-ch-dv:before {
    left: 0;
    z-index: -1;
  }
  .build-resume-sec .card-custom {
    width: 100%;
    height: 280px;
    box-shadow: 1px 0.71161px 3.71161px 0px rgba(0, 0, 0, 0.25);
  }
  .build-resume-sec .card-custom-second {
    width: 100%;
    height: 280px;
    margin-top: 20px;
  }
  .build-resume-sec .bulid-heading span {
    font-size: 25px;
  }
  .build-resume-sec .bulid-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .bulid-btn button {
    font-size: 14px;
  }
  .build-resume-sec .display-img {
    margin-top: 20px;
  }
  .build-resume-sec .bulid-resume-heading h3 {
    font-size: 20px;
  }
  .build-resume-sec .bulid-resume-heading span {
    color: #0A65CC;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .build-resume-sec .position-section {
    position: absolute;
    top: 8%;
    right: 6%;
    z-index: -2;
  }
  .build-resume-sec .position-third-section {
    position: absolute;
    top: 42%;
    left: 42%;
    z-index: -1;
  }
  .build-resume-sec .position-second-section {
    position: absolute;
    top: 63%;
    right: 17%;
    z-index: -1;
  }
  .build-resume-sec .Testimonials-top-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .Snapshot-section {
    padding: 0px;
  }
  .build-resume-sec .Snapshot-top-heading h3 {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
  }
  .build-resume-sec .benefits-top-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .benefits-top-heading h3 h5 {
    color: #1B375C;
    font-size: 22px;
    margin-top: 10px;
    font-weight: 600;
  }
  .build-resume-sec .about-top-heading h3 {
    font-size: 28px;
  }
  .build-resume-sec .Testimonials-heading h5 {
    font-size: 18px;
  }
  .build-resume-sec .benefits-card-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    margin-top: 20px;
  }
  .build-resume-sec .benefits-card-section .benefits-card-section .benefits-card-child {
    width: 100%;
  }
  .build-resume-sec .benefits-card-section .benefits-card-child {
    width: 100%;
  }
  .build-resume-sec .template-top-heading h5 {
    color: #1B375C;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
  }
  .build-resume-sec .template-top-heading h3 {
    font-size: 20px;
  }
  .build-resume-sec .Snapshot-right-para h2 {
    font-size: 28px;
  }
  .build-resume-sec .Snapshot-right-list li {
    color: #1B375C;
    letter-spacing: 0.232px;
    font-size: 18px;
    padding: 8px;
    font-weight: 700;
  }
  .build-resume-sec .Snapshot-right-list li span {
    color: #1B375C;
    font-size: 16px;
    font-weight: 500;
  }
  .build-resume-sec .template-bg {
    background: #f7f7f7;
    padding: 10px;
  }
  .build-resume-sec .Testimonials-section {
    padding: 20px 0px;
  }
  .build-resume-sec .template-right-side {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .build-resume-sec .ab-right-img .right-img-ch-dv:after {
    right: 0px;
    z-index: -1;
  }
  .build-resume-sec .ab-right-img .right-img-ch-dv:before {
    left: 0;
    z-index: -1;
  }
  .build-resume-sec .card-custom {
    width: 100%;
    height: 280px;
    box-shadow: 1px 0.71161px 3.71161px 0px rgba(0, 0, 0, 0.25);
  }
  .build-resume-sec .card-custom-second {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
  .build-resume-sec .bulid-heading span {
    font-size: 25px;
  }
  .build-resume-sec .bulid-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .bulid-btn button {
    font-size: 14px;
  }
  .build-resume-sec .display-img {
    margin-top: 20px;
  }
  .build-resume-sec .bulid-resume-heading h3 {
    font-size: 20px;
  }
  .build-resume-sec .bulid-resume-heading span {
    color: #0A65CC;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .build-resume-sec .position-section {
    position: absolute;
    top: 8%;
    right: 6%;
    z-index: -2;
  }
  .build-resume-sec .position-third-section {
    position: absolute;
    top: 42%;
    left: 42%;
    z-index: -1;
  }
  .build-resume-sec .position-second-section {
    position: absolute;
    top: 63%;
    right: 17%;
    z-index: -1;
  }
  .build-resume-sec .Testimonials-top-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .Snapshot-section {
    padding: 0px;
  }
  .build-resume-sec .Snapshot-top-heading h3 {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
  }
  .build-resume-sec .benefits-top-heading h3 {
    font-size: 25px;
  }
  .build-resume-sec .benefits-top-heading h3 h5 {
    color: #1B375C;
    font-size: 22px;
    margin-top: 10px;
    font-weight: 600;
  }
  .build-resume-sec .about-top-heading h3 {
    font-size: 28px;
  }
  .build-resume-sec .Testimonials-heading h5 {
    font-size: 18px;
  }
  .build-resume-sec .benefits-card-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    margin-top: 20px;
  }
  .build-resume-sec .benefits-card-section .benefits-card-section .benefits-card-child {
    width: 100%;
  }
  .build-resume-sec .benefits-card-section .benefits-card-child {
    width: 100%;
  }
  .build-resume-sec .template-top-heading h5 {
    color: #1B375C;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
  }
  .build-resume-sec .template-top-heading h3 {
    font-size: 20px;
  }
  .build-resume-sec .Snapshot-right-para h2 {
    font-size: 28px;
  }
  .build-resume-sec .Snapshot-right-list li {
    color: #1B375C;
    letter-spacing: 0.232px;
    font-size: 18px;
    padding: 8px;
    font-weight: 700;
  }
  .build-resume-sec .Snapshot-right-list li span {
    color: #1B375C;
    font-size: 16px;
    font-weight: 500;
  }
  .build-resume-sec .template-bg {
    background: #f7f7f7;
    padding: 10px;
  }
  .build-resume-sec .Testimonials-section {
    padding: 0px;
  }
}/*# sourceMappingURL=BuildYourResume.css.map */