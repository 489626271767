.rec-document-varification-form-sec .form-head-dv {
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding: 30px;
  margin: 60px 0px;
}
.rec-document-varification-form-sec .form-head-dv .next-pre-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.rec-document-varification-form-sec .form-head-dv .next-button .next-btn {
  background: rgb(27, 55, 92);
  color: #fff;
  border-radius: 32px;
  padding: 10px 70px;
  margin: 4rem 10px 0px 10px;
  border: none;
  text-decoration: none;
}
.rec-document-varification-form-sec .form-head-dv .prev-button .prev-btn {
  background: #fff;
  color: rgb(27, 55, 92);
  border-radius: 32px;
  padding: 10px 70px;
  margin: 4rem 10px 0px 10px;
  border: 1px solid rgb(27, 55, 92);
  text-decoration: none;
}
.rec-document-varification-form-sec .form-head-dv .cd-heading {
  text-align: left;
}
.rec-document-varification-form-sec .form-head-dv .cd-heading h2 {
  font-size: 28px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file label.label input[type=file] {
  position: absolute;
  top: -1000px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label {
  cursor: pointer;
  border: 1px solid rgb(173, 173, 173);
  width: 100%;
  border-radius: 9px;
  padding: 7px 15px 7px 15px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label .upload-icon {
  float: right;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label span {
  color: #797b7e;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label:hover {
  background: #ccc;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label:active {
  background: #ccc;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label:invalid + span {
  color: #000000;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-file .label:valid + span {
  color: #ffffff;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-f {
  width: 100%;
  text-align: left;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-f label {
  margin-bottom: 5px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-f input {
  border: 1px solid rgb(173, 173, 173);
  width: 100%;
  border-radius: 9px;
  padding: 7px 15px 7px 15px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-f-btn {
  margin-top: 26px;
}
.rec-document-varification-form-sec .form-head-dv .cd-form-f-btn input {
  background: linear-gradient(0deg, #0A65CC, #0A65CC), linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: #fff;
  padding: 8px 15px 8px 15px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-document-varification-form-sec .form-head-dv .prev-button .prev-btn {
    background: #fff;
    color: rgb(27, 55, 92);
    border-radius: 32px;
    padding: 10px 20px;
    margin: 4rem 5px 0px 5px;
    border: 1px solid rgb(27, 55, 92);
    text-decoration: none;
  }
  .rec-document-varification-form-sec .form-head-dv .next-button .next-btn {
    background: rgb(27, 55, 92);
    color: #fff;
    border-radius: 32px;
    padding: 10px 40px;
    margin: 4rem 5px 0px 5px;
    border: none;
    text-decoration: none;
  }
}/*# sourceMappingURL=Documentcss.css.map */