@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    //     .sidebar-menu-parent {
    //     .sidebar-menu {
    //         .sidebar-link {
    //             height: 340px;
    //             overflow-y: scroll;
    //         }
    //     }
    // }

    .rec-after-login-sec {
        .main-header {
            .head-sec-lft {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
            }
            .head-sec-rit {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                margin: 10px 0px;
            }
         }
        .header-second {

            .navbar-ul-custom {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }

            .navbar-nav {
                li {
                    margin: 10px 5px;
                }
            }
        }

        .sidebar-menu-parent {
            .sidebar-menu {
                .sidebar-link {
                    height: 340px;
                    overflow-y: scroll;
                }

                .sidebar-unlist {
                    li {
                        &:last-child {
                            margin-bottom: 130px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .rec-after-login-sec {
        .main-header {
            .head-sec-lft {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
            }
            .head-sec-rit{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                margin: 10px 0px;
                .search-top-area-dv{
                    .recent-search-dv{
                        display: none;
                    }
                }
            }
        }
        .header-second {
            display: block;

            .menu-tab {
                margin-right: 0px;
            }

            .navbar-ul-custom {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }

        .modal-body-content {
            padding: 9px 0px;
        }

        .sidebar-menu-parent {
            .sidebar-menu {
                .sidebar-link {
                    height: 340px;
                    overflow-y: scroll;
                }
            }
        }
    }
}
@media only screen and (min-width:200px) and (max-width:320px){
    .rec-after-login-sec {
        .sidebar-menu-parent {
            .sidebar-menu {
                .sidebar-menu-btn {
                    width: 60px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    right: 0px;
                    cursor: pointer;
                }
            }
        }
    }
}