.company-listing-content-section {
    margin: 40px 0px;

    .back-btn {
        .jobs-heading {
            margin-bottom: 10px;
        }

        .arror-icon {
            margin: 0px 0px 20px 0px;

            a {
                text-decoration: none;
            }
        }
    }

    .banner-section {
        border-radius: 8px;
        border: 1px solid rgba(20, 20, 20, 0.05);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    }

    .list-section {
        ul {
            display: flex;

            li {
                list-style: none;
                padding: 0px 100px 5px 1px;
                color: rgba(20, 20, 20, 0.70);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;

                img {
                    margin-right: 8px;
                }
            }
        }
    }

    .para {
        p {
            color: rgba(20, 20, 20, 0.70);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            padding: 0px 0px 0px 10px;
        }
        span {
            color: #0A65CC;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-left: 80px;
            a {
                text-decoration: none;
            }
        }
    }

    .frame-img {
        border-radius: 4px;
        margin: 15px;

    }

    .heading {
        .new-post-span {
            margin-left: 10px;

            a {
                padding: 2px 6px;
                border-radius: 3px;
                background: rgba(125, 90, 226, 0.10);
                color: #7D5AE2;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                text-decoration: none;
            }
        }

    }



    .bg-second {
        border-radius: 4px;
        background: #FFF;
        padding: 40px;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }


    .pad-custom {
        padding: unset;
    }

    .top-para {
        font-size: 18px;
        margin-bottom: 0px;
    }


    .page-item {
        padding: 6px;
    }

    .page-link {
        color: black;
        font-size: 20px;
    }

    .heading h2 {
        color: #141414;
        font-size: 24px;
        font-weight: 500;
        a{
            text-decoration: none;
            color: #141414;
        }
    }
}


@media only screen and (min-width:768px) and (max-width:992px) {
    .company-listing-content-section {
        .para {
            p {
                color: rgba(20, 20, 20, 0.7);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                padding: 0px 0px 0px 10px;
                margin-left: 0px;
                padding-left: 0px;
            }
        }
        .list-section {
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0px;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .company-listing-content-section {
        .heading {
            .new-post-span {
                a {
                margin-left: 0px;
                }
            }
            h2 {
                color: #141414;
                font-size: 24px;
                font-weight: 500;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }
        .banner-section {
            border-radius: 8px;
            border: 1px solid rgba(20, 20, 20, 0.05);
            background: #FFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
            margin: 0px 25px;
        }

        .frame-img {
            margin: 15px 0px 0px 0px;
        }

        .list-section {
            ul {
                li {
                    list-style: none;
                    padding: unset;
                }
            }
        }

        .list-section ul {

            flex-direction: column;
        }

        .heading a {

            margin-left: 50px;
        }

        .form-btn2 {

            width: 190px !important;

        }

        .bg-second {
            padding: 0px !important;
            text-align: center;
        }

        .ptn {
            margin: 20px;
            margin: 0px 20px;
        }

        .para span a {

            margin: 37px;
        }

        .pagintion-custom {
            margin-left: 7px !important;
            margin-top: 23px !important;
        }

        .page-item {
            padding: 2px !important;
        }
    }
}