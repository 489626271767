.videoint {
    .custm-card {
        background-image: url(../../../../../../public/assets/images/matching-hiring/crd.png);
        background-size: cover;
        background-position: 100%;
        height: 350px;
        padding: 30px 40px;

        h3 {
            color: #1B375C;
            font-size: 28px;
            font-weight: 700;
        }

        ul {
            padding: unset;

            li {
                color: #1E1E1E;
                text-align: justify;
                font-size: 18px;
                margin: 0 0 0 20px;
            }

            li:nth-child(1) {
                list-style: none;
                margin: unset;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:992px) {
    .videoint {
        .custm-card {
            padding: 30px 30px;

            h3 {
                font-size: 20px;
            }

            ul {
                li {
                    font-size: 15px;
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .videoint {
        .custm-card {
            height: auto;
            padding: 10px 10px;
            background-position: center;
            h3 {
                font-size: 18px;
                font-weight: 600;
            }

            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }
}