.career-result-details-section {
  margin: 20px 0px;
}
.career-result-details-section .career-section {
  background-image: url("../../../../../../public/assets/career-start/career-start-banner.png");
  background-position: 100%;
  background-size: cover;
  padding: 85px;
}
.career-result-details-section .career-section .career-top-heading h2 {
  text-align: center;
  font-size: 50px;
  font-weight: 400;
}
.career-result-details-section .career-section .career-top-heading span {
  text-align: center;
  color: #1B375C;
  font-size: 50px;
  font-weight: 700;
}
.career-result-details-section .career-section .career-second-heading h6 {
  color: #1B375C;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
  width: 40%;
  border-radius: 10px;
  padding: 5px;
}
.career-result-details-section .career-section .career-second-heading span {
  color: #1B375C;
  font-size: 18px;
  font-weight: 700;
}
.career-result-details-section .career-section .custom-form-section {
  display: flex !important;
}
.career-result-details-section .search-custom {
  position: relative;
  width: 332px;
}
.career-result-details-section .search-custom:nth-child(1) .form-custom {
  border-radius: 5px 0px 0px 5px;
}
.career-result-details-section .search-custom:nth-child(2) .form-custom {
  border-radius: 0px;
}
.career-result-details-section .search-custom:nth-child(3) .form-custom {
  border-radius: 0px;
}
.career-result-details-section .search-custom .form-custom {
  height: 53px;
}
.career-result-details-section .search-custom input::-moz-placeholder {
  padding: 0 0 0 50px;
}
.career-result-details-section .search-custom input::placeholder {
  padding: 0 0 0 50px;
}
.career-result-details-section .search-custom img {
  position: absolute;
  top: 11px;
  left: 15px;
  color: #b0a7a7;
}
.career-result-details-section .form-custom-btn button {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #FFF;
  width: 102px;
  height: 53px;
  font-size: 15px;
  font-weight: 500;
}
.career-result-details-section .dropdown-custom-btn {
  display: flex !important;
  justify-content: flex-start;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.career-result-details-section .dropdown-custom-btn .dropdown {
  margin: 10px 10px;
}
.career-result-details-section .dropdown-second-custom-btn {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}
.career-result-details-section .btn-custom {
  border-radius: 7px;
  background: #FFF;
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  border: none;
}
.career-result-details-section .career-second-section .ptn .top-para {
  margin-bottom: 0px;
}
.career-result-details-section .career-second-section .card-section {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 30px;
}
.career-result-details-section .career-second-section .card-section .form-heading h3 {
  color: #141414;
  font-size: 30px;
  font-weight: 600;
}
.career-result-details-section .career-second-section .card-section .form-desgin {
  border-radius: 8px;
  border: 1px solid #0A65CC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  padding: 19px;
}
.career-result-details-section .career-second-section .card-section .ptn .top-para p {
  color: #141414;
  font-size: 18px;
  font-weight: 400;
}
.career-result-details-section .career-second-section .card-section .ptn .heading h2 {
  color: #141414;
  font-size: 24px;
  font-weight: 500;
}
.career-result-details-section .career-second-section .card-section .ptn .heading h2 a {
  padding: 2px 6px;
  border-radius: 3px;
  background: rgba(125, 90, 226, 0.1);
  color: #7D5AE2;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.career-result-details-section .career-second-section .card-section .ptn .list-section ul {
  display: flex;
  justify-content: space-between;
  padding: unset;
}
.career-result-details-section .career-second-section .card-section .ptn .list-section ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.career-result-details-section .career-second-section .card-section .ptn .list-section ul li img {
  margin-right: 5px;
}
.career-result-details-section .career-second-section .info-section {
  border-radius: 4px;
  background: #FFF;
  padding: 25px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.career-result-details-section .career-second-section .info-section .top-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child {
  display: flex;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head {
  margin-left: 10px;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head h3 {
  font-size: 21px;
  margin-bottom: 0px;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p {
  margin-top: 10px;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p span:nth-child(2) {
  background: rgb(11, 160, 44);
  color: #fff;
  border-radius: 3px;
  padding: 2px 10px;
  margin: 0px 5px;
}
.career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p span:nth-child(3) {
  background: rgb(255, 237, 237);
  color: rgb(224, 81, 81);
  border-radius: 20px;
  padding: 2px 10px;
  margin: 0px 5px;
}
.career-result-details-section .career-second-section .info-section .top-header .top-btn a {
  text-decoration: none;
}
.career-result-details-section .career-second-section .info-section .bookmark-apply-dv {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.career-result-details-section .career-second-section .info-section .bookmark-apply-dv .apply-btn-bookmark {
  padding: 15px 32px 15px 32px;
  background: rgb(10, 101, 204);
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}
.career-result-details-section .career-second-section .info-section .bookmark-apply-dv .apply-btn-bookmark .apply-tag {
  text-decoration: none;
  color: #fff;
}
.career-result-details-section .career-second-section .info-section .salary-location-dv {
  margin: 20px 0px;
  border: 2px solid rgb(231, 240, 250);
  padding: 15px;
  border-radius: 8px;
}
.career-result-details-section .career-second-section .info-section .salary-location-dv p {
  margin-bottom: 0px;
}
.career-result-details-section .career-second-section .info-section .salary-location-dv .border-right {
  border-right: 2px solid #ccc;
}
.career-result-details-section .career-second-section .info-section .salary-location-dv .left-section h5 {
  margin-bottom: 0px;
  color: rgb(11, 160, 44);
}
.career-result-details-section .career-second-section .info-section .salary-location-dv .left-section2 h5 {
  margin-bottom: 0px;
}
.career-result-details-section .career-second-section .info-section .left-card-second {
  border: 2px solid rgb(231, 240, 250);
  border-radius: 8px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .border-bottom-cus {
  border-bottom: 2px solid rgb(231, 240, 250);
}
.career-result-details-section .career-second-section .info-section .left-card-second .list-head {
  margin-bottom: 10px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .list-head h3 {
  font-size: 20px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading {
  padding: 20px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list .social-media-parent {
  margin-right: 10px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .card-pad {
  margin-bottom: 20px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .card-pad img {
  margin-bottom: 10px;
}
.career-result-details-section .career-second-section .info-section .left-card-second .card-pad p {
  margin-bottom: 0px;
  color: #7c7b7b;
}
.career-result-details-section .career-second-section .info-section .left-card-second .card-pad h6 {
  font-size: 15px;
}
.career-result-details-section .career-second-section .info-section .info-section-heading {
  padding: 30px;
}
.career-result-details-section .career-second-section .info-section .info-section-heading .info-dv-para-scroll {
  height: 600px;
  overflow-y: scroll;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .career-result-details-section .career-section .dropdown-custom-btn {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .career-result-details-section .career-section .dropdown-custom-btn .dropdown {
    margin: 5px 7px;
  }
  .career-result-details-section .dropdown-custom-btn {
    display: flex !important;
    margin-top: 30px;
    flex-direction: column;
  }
  .career-result-details-section .career-section .custom-form-section {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .career-result-details-section .dropdown-second-custom-btn {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
  }
  .career-result-details-section .dropdown-second-custom-btn .dropdown {
    margin: 5px 7px;
  }
  .career-result-details-section .search-custom {
    position: relative;
    margin-top: 10px;
  }
  .career-result-details-section .search-custom .form-custom {
    height: 40px;
    border-radius: 5px !important;
  }
  .career-result-details-section .form-custom-btn {
    margin-top: 10px;
  }
  .career-result-details-section .form-custom-btn button {
    border-radius: 5px;
    background: #1B375C;
    color: #FFF;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 0px 40px;
  }
  .career-result-details-section .career-section {
    padding: 20px 0px;
  }
  .career-result-details-section .career-section .career-top-heading h2 {
    text-align: left;
    font-size: 30px;
    font-weight: 400;
  }
  .career-result-details-section .career-section .career-top-heading span {
    text-align: center;
    color: #1B375C;
    font-size: 30px;
    font-weight: 700;
  }
  .career-result-details-section .career-section .career-second-heading h6 {
    color: #1B375C;
    font-size: 16px;
    font-weight: 500;
  }
  .career-result-details-section .career-section .career-second-heading span {
    color: #1B375C;
    font-size: 16px;
    font-weight: 700;
  }
  .career-result-details-section .btn-custom {
    border-radius: 7px;
    background: #FFF;
    color: #1B375C;
    font-size: 11px;
    font-weight: 500;
    border: none;
  }
  .career-result-details-section .career-second-section .card-section .form-heading h3 {
    color: #141414;
    font-size: 18px;
    font-weight: 600;
  }
  .career-result-details-section .career-second-section .card-section .ptn p {
    text-align: center;
  }
  .career-result-details-section .career-second-section .card-section .ptn .heading h2 {
    color: #141414;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .card-section .ptn .list-section ul {
    display: flex;
    justify-content: space-around;
    padding: unset;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head {
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p span:nth-child(3) {
    margin-top: 10px !important;
  }
  .career-result-details-section .career-second-section .info-section .salary-location-dv .border-right {
    border-bottom: none;
    border-right: 2px solid #ccc;
    margin: 10px 0px;
    padding: 10px 0px;
  }
  .career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list .social-media-parent {
    margin-right: 10px;
    margin-top: 10px;
  }
  .career-result-details-section .info-dv-para {
    text-align: justify;
    word-wrap: break-word;
    word-break: break-all;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .career-result-details-section .career-section .dropdown-custom-btn {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .career-result-details-section .career-section .dropdown-custom-btn .dropdown {
    margin: 5px 7px;
  }
  .career-result-details-section .dropdown-custom-btn {
    display: flex !important;
    margin-top: 30px;
    flex-direction: column;
  }
  .career-result-details-section .career-section .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .career-result-details-section .dropdown-second-custom-btn {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
  }
  .career-result-details-section .dropdown-second-custom-btn .dropdown {
    margin: 5px 7px;
  }
  .career-result-details-section .search-custom {
    position: relative;
    width: 100%;
    margin-top: 5px;
  }
  .career-result-details-section .search-custom .form-custom {
    height: 40px;
    border-radius: 5px !important;
  }
  .career-result-details-section .form-custom-btn button {
    border-radius: 5px;
    background: #1B375C;
    color: #FFF;
    width: 100%;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    outline: none;
    margin-top: 5px;
  }
  .career-result-details-section .career-section {
    padding: 20px 0px;
  }
  .career-result-details-section .career-section .career-top-heading h2 {
    text-align: left;
    font-size: 24px;
    font-weight: 400;
  }
  .career-result-details-section .career-section .career-top-heading span {
    text-align: center;
    color: #1B375C;
    font-size: 24px;
    font-weight: 700;
  }
  .career-result-details-section .career-section .career-second-heading h6 {
    color: #1B375C;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
  }
  .career-result-details-section .career-section .career-second-heading span {
    color: #1B375C;
    font-size: 16px;
    font-weight: 700;
  }
  .career-result-details-section .btn-custom {
    border-radius: 7px;
    background: #FFF;
    color: #1B375C;
    font-size: 11px;
    font-weight: 500;
    border: none;
  }
  .career-result-details-section .career-second-section .card-section .form-heading h3 {
    color: #141414;
    font-size: 18px;
    font-weight: 600;
  }
  .career-result-details-section .career-second-section .card-section .ptn p {
    text-align: center;
  }
  .career-result-details-section .career-second-section .card-section .ptn .heading h2 {
    color: #141414;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .card-section .ptn .list-section ul {
    display: flex;
    justify-content: space-around;
    padding: unset;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head {
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .top-header .top-header-child .top-head p span:nth-child(3) {
    margin-top: 10px !important;
  }
  .career-result-details-section .career-second-section .info-section .salary-location-dv .border-right {
    border-bottom: 2px solid #ccc;
    border-right: none;
    margin: 10px 0px;
    padding: 10px 0px;
  }
  .career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .career-result-details-section .career-second-section .info-section .left-card-second .list-top-heading .card-list .social-media-parent {
    margin-right: 10px;
    margin-top: 10px;
  }
  .career-result-details-section .info-dv-para {
    text-align: justify;
    word-wrap: break-word;
    word-break: break-all;
  }
}/*# sourceMappingURL=CareerSearchResultListingAndDetails.css.map */