.rec-myprofile-section .main-head {
  padding: 1px;
  border-radius: 4px;
  background: #F4FAFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(29, 95, 181, 0.2) inset;
}
.rec-myprofile-section .main-head h2 {
  color: #1B375C;
  /* font-family: Barlow; */
  font-size: 22px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 54px;
  text-align: center;
  text-transform: capitalize;
}
.rec-myprofile-section .main-section {
  padding: 40px;
}
.rec-myprofile-section .banner-section {
  border-radius: 4px;
  background: #FFF;
  padding: 10px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.rec-myprofile-section .banner-second {
  padding: 55px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.rec-myprofile-section .banner-bg {
  display: flex;
  justify-content: space-between;
  z-index: 9;
  position: relative;
}
.rec-myprofile-section .banner-bg .profile-images img {
  margin: -80px 0 0 0;
}
.rec-myprofile-section .banner-bg .first-head-bg h4 {
  font-size: 24px;
  margin: 0 0 5px 0;
}
.rec-myprofile-section .banner-bg .first-head-bg p {
  font-size: 14px;
}
.rec-myprofile-section .banner-bg img {
  padding: 12px;
}
.rec-myprofile-section .banner-right-bg .banner-play-btn {
  border-radius: 10px;
  border: 1px solid #0A65CC;
  background: #0A65CC;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-myprofile-section .banner-right-bg {
  display: flex;
  align-items: center;
}
.rec-myprofile-section .banner-right-bg .banner-right-img {
  padding: 6px;
}
.rec-myprofile-section .first-head-bg {
  margin-top: 18px;
  margin-left: 17px;
}
.rec-myprofile-section .bg-img {
  padding: 10px;
}
.rec-myprofile-section .profile-banner-head {
  position: relative;
}
.rec-myprofile-section .profile-banner-head .profile-heading-top {
  position: absolute;
  top: 35px;
  left: 50px;
}
.rec-myprofile-section .profile-banner-head .profile-heading-top h2 {
  font-weight: 700;
}
.rec-myprofile-section .user-section {
  background: #FFF;
  padding: 20px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -2px -2px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.rec-myprofile-section .top-user-section {
  display: flex;
  justify-content: space-between;
}
.rec-myprofile-section .top-user-section .top-user-head h6 {
  font-size: 24px;
}
.rec-myprofile-section .top-user-section .top-user-head h4 {
  margin-bottom: 0px;
  font-size: 20px;
}
.rec-myprofile-section .top-user-section .top-user-head .first-para {
  color: var(--light-text-secondary, rgba(28, 28, 30, 0.72));
  margin-bottom: 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.rec-myprofile-section .see-more-para span a {
  text-decoration: none;
}
.rec-myprofile-section .top-user-section .edit-btn {
  padding: 10px 20px;
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #51b6dd;
  border-radius: 13px;
  background: #fff;
}
.rec-myprofile-section .user-details {
  display: flex;
  justify-content: space-between;
}
.rec-myprofile-section .user-details p {
  color: #000;
  font-weight: 600;
}
.rec-myprofile-section .user-details h6 {
  color: var(--light-text-disabled, rgb(124, 121, 121));
}
.rec-myprofile-section .edit-btn2 {
  color: #51b6dd;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #51b6dd;
  border-radius: 13px;
  background: #fff;
  height: 45px;
  width: 121px;
}
.rec-myprofile-section .border-bottom {
  border: 1px solid rgba(28, 28, 30, 0.12);
  width: 100%;
}
.rec-myprofile-section .card-icon-section {
  display: flex;
  justify-content: space-between;
}
.rec-myprofile-section .card-icon-section h4 {
  font-size: 20px;
}
.rec-myprofile-section .card-custom {
  border-radius: 8px;
  border: 1px solid #F2F2F7;
}
.rec-myprofile-section .icon-card-section {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.rec-myprofile-section .icon-card-section .icon-card-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
}
.rec-myprofile-section .icon-card-section img.social-img {
  margin-right: 20px;
}
.rec-myprofile-section .icon-card-section p {
  margin-bottom: 0px;
}
.rec-myprofile-section .icon-card-section .icon-card-custom {
  border-radius: 10px;
  border: 1px solid rgba(52, 67, 75, 0.15);
}
.rec-myprofile-section .icon-card-section .trush-icon {
  display: flex;
  margin-left: 70px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rec-myprofile-section .icon-card-section .second-bg-section {
  background: #1DB5BE;
}
.rec-myprofile-section .icon-card-section .second-bg-head h2 {
  color: #FFF;
  font-feature-settings: "liga" off;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.2px;
}
.rec-myprofile-section .icon-card-section .second-bg-head p {
  color: #111827;
  text-align: justify;
  font-feature-settings: "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.rec-myprofile-section .icon-card-section .mark-top {
  margin-top: 120px;
}
.rec-myprofile-section .last-element-show-more {
  margin-bottom: 15px;
}
.rec-myprofile-section .last-element-show-more a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .rec-myprofile-section .first-head h2 {
    font-size: 18px;
  }
  .rec-myprofile-section .first-head span {
    font-size: 18px;
  }
  .rec-myprofile-section .profile-banner-head {
    position: relative;
  }
  .rec-myprofile-section .profile-banner-head .profile-heading-top {
    position: absolute;
    top: 25px;
    left: 50px;
  }
  .rec-myprofile-section .profile-banner-head .profile-heading-top h2 {
    font-weight: 700;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .rec-myprofile-section {
    margin: 10px 0px;
  }
  .rec-myprofile-section .top-user-section .edit-btn {
    padding: 0px 10px;
    font-size: 16px;
  }
  .rec-myprofile-section .user-section {
    padding: 0px;
  }
  .rec-myprofile-section .card-icon-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .rec-myprofile-section .card-icon-section .trash-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-myprofile-section .top-user-section .d-flex {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .rec-myprofile-section .top-user-section .display-btn-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
  }
  .rec-myprofile-section .profile-banner-head {
    position: relative;
  }
  .rec-myprofile-section .profile-banner-head .profile-heading-top {
    position: absolute;
    top: 0px;
    left: 50px;
  }
  .rec-myprofile-section .profile-banner-head .profile-heading-top h2 {
    font-weight: 700;
  }
  .rec-myprofile-section .banner-bg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .rec-myprofile-section .main-section {
    padding: 0px !important;
  }
  .rec-myprofile-section .icon-card-section .icon-card-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
  }
  .rec-myprofile-section .icon-card-section img.social-img {
    margin-right: 3px;
  }
  .rec-myprofile-section .top-user-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
  .rec-myprofile-section .first-head h2 {
    font-size: 18px;
  }
  .rec-myprofile-section .first-head span {
    font-size: 18px;
  }
  .reclogincontent-section .login-btn a {
    padding: 10px 100px;
  }
}/*# sourceMappingURL=RecMyProfile.css.map */