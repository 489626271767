.find-company-listing-section {
  margin: 30px 0px;
  /* first-section-start-css */
  /* second-section-start-css */
}
.find-company-listing-section .career-section {
  background-image: url("../../../../../../public/assets/find-right-places-companies/career-start-banner.png");
  background-position: 100%;
  background-size: cover;
  padding: 85px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.find-company-listing-section .career-section .career-top-heading h2 {
  text-align: center;
  font-weight: 400;
}
.find-company-listing-section .career-section .career-top-heading h1 {
  text-align: center;
  font-weight: 400;
}
.find-company-listing-section .career-section .career-top-heading span {
  text-align: center;
  color: #1B375C;
  font-size: 50px;
  font-weight: 700;
}
.find-company-listing-section .career-section .career-second-heading h6 {
  color: #1B375C;
  font-weight: 500;
  background: #fff;
  width: 40%;
  border-radius: 5px;
  padding: 5px 5px 5px 15px;
}
.find-company-listing-section .career-section .career-second-heading span {
  color: #1B375C;
  font-size: 17px;
  font-weight: 700;
}
.find-company-listing-section .career-section .custom-form-section {
  display: flex !important;
  justify-content: space-between;
}
.find-company-listing-section .search-custom {
  position: relative;
  width: 332px;
}
.find-company-listing-section .search-custom .form-custom {
  height: 53px;
  width: 487px;
  border-radius: 0px;
}
.find-company-listing-section .search-custom:nth-child(1) .form-custom {
  border-radius: 5px 0px 0px 5px;
}
.find-company-listing-section .search-custom input::-moz-placeholder {
  padding: 0 0 0 50px;
}
.find-company-listing-section .search-custom input::placeholder {
  padding: 0 0 0 50px;
}
.find-company-listing-section .search-custom img {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #b0a7a7;
}
.find-company-listing-section .form-custom-btn {
  border-radius: 0px 8px 8px 0px;
  background: #1B375C;
  color: #FFF;
  width: 144px;
  height: 53px;
  font-size: 13px;
  font-weight: 500;
}
.find-company-listing-section .dropdown-custom-btn {
  display: flex !important;
  justify-content: flex-start;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.find-company-listing-section .dropdown-custom-btn .dropdown {
  margin: 10px 10px;
}
.find-company-listing-section .dropdown-second-custom-btn {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.find-company-listing-section .btn-custom {
  border-radius: 7px;
  background: #FFF;
  color: #1B375C;
  font-size: 18px;
  font-weight: 600;
  border: none;
}
.find-company-listing-section .career-second-section .card-section {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 30px;
}
.find-company-listing-section .career-second-section .card-section .form-heading h3 {
  color: #141414;
  font-size: 30px;
  font-weight: 600;
}
.find-company-listing-section .career-second-section .card-section .form-desgin {
  border-radius: 8px;
  border: 1px solid #0A65CC;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  padding: 19px;
}
.find-company-listing-section .career-second-section .card-section .ptn .top-para p {
  color: #141414;
  font-size: 18px;
  font-weight: 400;
}
.find-company-listing-section .card-custom {
  border-radius: 4px;
  background: #FFF;
  padding: 40px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.find-company-listing-section .main-section {
  padding: 50px;
}
.find-company-listing-section .card-bg {
  border-radius: 4px;
  padding: 50px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.find-company-listing-section .card-heading h3 {
  color: #141414;
  font-size: 32px;
  font-weight: 700;
}
.find-company-listing-section .footer-icon h3 {
  color: #141414;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.find-company-listing-section .footer-icon a {
  color: #FE6108;
  border-radius: 3px;
  background: rgba(254, 97, 8, 0.1);
  font-size: 12px;
  padding: 2px 6px;
  font-weight: 500;
  text-decoration: none;
}
.find-company-listing-section .company-heading-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.find-company-listing-section .company-heading-icon .company-card h3 {
  color: #141414;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.find-company-listing-section .company-heading-icon .company-card h3 a {
  color: #141414;
  border-radius: 3px;
  background: none;
  font-size: 16px;
  padding: unset;
  font-weight: 500;
  text-decoration: none;
}
.find-company-listing-section .company-heading-icon .company-card h3 a:hover {
  text-decoration: underline;
}

/*====================================== media-query-start =============================================*/
@media only screen and (min-width: 768px) and (max-width: 920px) {
  .find-company-listing-section .career-section .custom-form-section {
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
  }
  .find-company-listing-section .main-section {
    padding: 15px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .find-company-listing-section .footer-icon h3 {
    margin-bottom: 0px;
  }
  .find-company-listing-section .career-section {
    padding: 0px;
  }
  .find-company-listing-section .career-section .career-start-section {
    padding: 20px 0px;
  }
  .find-company-listing-section .career-section .career-top-heading h2 {
    font-size: 20px;
  }
  .find-company-listing-section .career-section .career-top-heading h1 {
    font-size: 20px;
  }
  .find-company-listing-section .career-section .career-top-heading span {
    font-size: 22px;
  }
  .find-company-listing-section .career-section .career-second-heading span {
    font-size: 16px;
  }
  .find-company-listing-section .career-section .career-second-heading h6 {
    font-size: 15px;
    width: 100%;
  }
  .find-company-listing-section .career-section .custom-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .find-company-listing-section .search-custom {
    margin-top: 13px;
    width: 100%;
  }
  .find-company-listing-section .search-custom .form-custom {
    width: 280px;
    height: 49px;
    padding: 0px;
    width: 100%;
  }
  .find-company-listing-section .form-custom-btn {
    margin-top: 12px;
    width: 100%;
    height: 36px;
    border-radius: 0px;
    border: none;
    outline: 0px;
  }
  .find-company-listing-section .dropdown-custom-btn {
    display: flex !important;
    margin-top: 15px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .find-company-listing-section .dropdown-custom-btn .dropdown {
    margin: auto 10px;
  }
  .find-company-listing-section .btn-custom {
    font-size: 16px;
    margin-top: 12px;
  }
  .find-company-listing-section .main-section {
    padding: 0px;
    margin-top: 20px;
  }
  .find-company-listing-section .card-bg {
    padding: 10px;
  }
  .find-company-listing-section .card-heading h3 {
    font-size: 22px;
    font-weight: 500;
  }
  .find-company-listing-section .card-custom {
    padding: 10px;
  }
  .find-company-listing-section .display-card {
    display: flex !important;
    flex-direction: column;
  }
}/*# sourceMappingURL=FindRightPlacesCompanyListing.css.map */