@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-banner {
  position: relative;
}
.header-banner .carousel .carousel-inner .carousel-item img {
  filter: brightness(0.7);
}
.header-banner .banner-text {
  position: absolute;
  left: 50%;
  top: 66%;
  transform: translate(-170%, -50%);
  text-align: center;
  animation: trans-jump;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.header-banner .banner-text h1 {
  color: #FFF;
  text-align: center;
  font-family: Barlow;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}
.header-banner .banner-text .find-job-btn {
  border-radius: 50px;
  background: #0A65CC;
  color: #fff;
  padding: 13px 25px;
  border: unset;
  text-decoration: none;
}
@keyframes trans-jump {
  from {
    opacity: 0%;
    transform: translate(-100%, -50%);
  }
  to {
    opacity: 100%;
    transform: translate(-50%, -50%);
  }
}

.bannerafter-header {
  background: #E9F4FE;
  padding: 50px;
}
.bannerafter-header .achievements-lft h1 {
  color: #1B375C;
  font-size: 40px;
  font-weight: 700;
  text-align: start;
}
.bannerafter-header .achievements-lft p {
  text-align: start;
}

.achievements-rit {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.achievements-rit .counter-img img {
  padding: 10px;
}
.achievements-rit .counts {
  display: flex;
}
.achievements-rit .counter-txt h2 {
  color: var(--neutral-900, #111827);
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.work-location {
  padding: 50px 0 0 0;
}
.work-location h1 {
  color: #1B375C;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
.work-location .slick-slider {
  padding: 20px 10px !important;
}
.work-location .slick-slider .slide {
  padding: 20px 0px;
  box-shadow: 5px 5px 5px 0px rgba(224, 224, 224, 0.5);
  margin: 15px 10px;
}
.work-location .slick-slider .slide p {
  margin: unset;
}
.work-location .slick-slider .slick-prev:before {
  content: "←";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #a19b9b;
}
.work-location .slick-slider .slick-next:before {
  content: "→";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #a19b9b;
}
.work-location .slick-slider div {
  padding: 0;
  border-radius: 20px;
}
.work-location .slick-slider div h3 {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
.work-location .slick-slider div p {
  text-align: center;
  font-size: 12px;
}

.job-opening {
  padding: 50px 0;
  border-radius: 4px;
  background: #F6F6F6;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.job-opening h1 {
  color: #1B375C;
  font-size: 40px;
  font-weight: 700;
}
.job-opening .css-1gsv261 {
  border: unset;
}
.job-opening .css-13xfq8m-MuiTabPanel-root {
  padding: 24px 0;
}
.job-opening .MuiTabs-flexContainer {
  border: unset;
  padding: 20px 0;
  justify-content: center;
}
.job-opening .MuiTabs-flexContainer .css-1gsv261 {
  border: unset;
}
.job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
  padding: 0 25px;
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px;
  border-radius: 60px;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: rgba(217, 217, 217, 0.06);
  box-shadow: 7px 9px 8px 0px rgba(14, 97, 194, 0.43);
}
.job-opening .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #1C385D;
  color: #ffffff;
}
.job-opening .css-1aquho2-MuiTabs-indicator {
  height: unset;
  background-color: unset;
}
.job-opening .latest-job-card {
  border-radius: 7px;
  background: #1B375C;
  padding: 20px;
  margin: 0 5px;
}
.job-opening .latest-job-card .company-group {
  margin-bottom: 0px;
}
.job-opening .latest-job-card .company-group .comp-logo {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}
.job-opening .latest-job-card .company-group img {
  width: 55px;
  height: 25px;
  flex-shrink: 0;
}
.job-opening .latest-job-card .company-group .job-role h5 {
  color: #FFF;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.job-opening .latest-job-card .company-group .job-role h6 {
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
}
.job-opening .latest-job-card .job-title {
  margin-top: 15px;
}
.job-opening .latest-job-card .job-title h5 {
  color: #FFF;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.job-opening .latest-job-card .job-title h6 {
  color: #F8F8F8;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.job-opening .latest-job-card .job-loc {
  margin-top: 20px;
}
.job-opening .latest-job-card .job-loc button {
  display: flex;
  align-items: center;
  width: 80px;
  height: 25px;
  border: unset;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  margin: 0 3px;
}
.job-opening .latest-job-card .job-loc button a {
  color: #FFF;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
}
.job-opening .latest-job-card-two {
  border-radius: 7px;
  background: #ffffff;
  padding: 20px;
  margin: 0 5px;
  border-radius: 7px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.job-opening .latest-job-card-two .company-group {
  margin-bottom: 0px;
}
.job-opening .latest-job-card-two .company-group .comp-logo {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}
.job-opening .latest-job-card-two .company-group img {
  width: 55px;
  height: 25px;
  flex-shrink: 0;
}
.job-opening .latest-job-card-two .company-group .job-role h5 {
  color: #000000;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.job-opening .latest-job-card-two .company-group .job-role h6 {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
}
.job-opening .latest-job-card-two .job-title {
  margin-top: 15px;
}
.job-opening .latest-job-card-two .job-title h5 {
  color: #000000;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.job-opening .latest-job-card-two .job-title h6 {
  color: #000000;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.job-opening .latest-job-card-two .job-loc {
  margin-top: 20px;
}
.job-opening .latest-job-card-two .job-loc button {
  display: flex;
  align-items: center;
  width: 80px;
  height: 25px;
  border: unset;
  border-radius: 5px;
  background: rgba(105, 105, 115, 0.1);
  padding: 5px 10px;
  margin: 0 3px;
}
.job-opening .latest-job-card-two .job-loc button a {
  color: #000000;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
}

.benifits {
  padding: 50px 0;
  background-color: #ffffff;
}
.benifits .key-benifits {
  padding: 50px;
}
.benifits .key-benifits .heading-txt h1 {
  color: #1B375C;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
}
.benifits .key-benifits p {
  text-align: left;
}
.benifits .key-points {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #E5D7CC;
  background: #FEFEFE;
  box-shadow: 5px 8px 19px 0px rgba(207, 207, 207, 0.28);
}
.benifits .key-points .text p {
  color: #2D2D2D;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.32px;
}
.benifits .benifit-sec-lft .cust-image {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
  background: #1B375C;
}
.benifits .benifit-sec-lft p {
  margin: 0 0 0 10px;
}
.benifits .benifit-sec-rit .cust-image {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
  background: #1B375C;
}
.benifits .benifit-sec-rit p {
  margin: 0 0 0 10px;
}

.job-demand {
  padding: 50px 0;
  background: rgba(173, 216, 255, 0.25);
  box-shadow: -4px -4px 10px 0px rgba(0, 144, 255, 0.1) inset;
}
.job-demand h1 {
  color: #1B375C;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}
.job-demand .job-box {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 25px;
  transition: all 0.4s ease-in;
}
.job-demand .job-box:hover {
  background: #1B375C;
}
.job-demand .job-box:hover h4 {
  color: #fff;
}
.job-demand .job-box:hover h5 {
  color: #fff;
}
.job-demand .job-box:hover .job-type p {
  color: #fff;
}
.job-demand .job-box:hover .job-type i {
  color: #fff;
}
.job-demand .job-box:hover .company .company-txt p {
  color: #fff;
}
.job-demand .job-box:hover .company .company-txt h5 {
  color: #fff;
}
.job-demand .job-box h4 {
  text-align: left;
  color: #000000;
  font-size: 24px;
  font-family: Barlow;
  font-weight: 500;
}
.job-demand .job-box .job-type {
  margin-bottom: 40px;
}
.job-demand .job-box .job-type i {
  color: #000000;
  margin-top: 5px;
}
.job-demand .job-box .job-type p {
  color: #000000;
  font-size: 14px;
  margin-bottom: unset;
}
.job-demand .job-box hr {
  width: 95%;
  color: #000000;
  text-align: center;
  margin-bottom: 25px;
}
.job-demand .job-box .company {
  height: 38px;
}
.job-demand .job-box .company .company-txt h5 {
  font-size: 16px;
  margin-bottom: unset;
  color: #000000;
}
.job-demand .job-box .company .company-txt p {
  margin-bottom: unset;
  color: #000000;
  font-size: 10px;
}
.job-demand .job-box .company button {
  background-color: #ffffff;
  color: #000;
  border: unset;
  font-size: 14px;
  border-radius: 0;
  padding: 0px 10px;
}
.job-demand .job-box2 {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
  border-radius: 7px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.job-demand .job-box2 h4 {
  text-align: left;
  color: #000000;
  font-size: 24px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.job-demand .job-box2 .job-type {
  margin-bottom: 40px;
}
.job-demand .job-box2 .job-type i {
  color: #000000;
  margin-top: 5px;
}
.job-demand .job-box2 .job-type p {
  font-size: 14px;
  color: #000000;
  margin-bottom: unset;
}
.job-demand .job-box2 hr {
  width: 95%;
  color: #ffffff;
  text-align: center;
  margin-bottom: 25px;
}
.job-demand .job-box2 .company {
  height: 38px;
}
.job-demand .job-box2 .company .company-txt h5 {
  font-size: 16px;
  margin-bottom: unset;
}
.job-demand .job-box2 .company .company-txt p {
  margin-bottom: unset;
  font-size: 10px;
}
.job-demand .job-box2 .company button {
  border: 1px solid rgba(0, 0, 0, 0.5764705882) !important;
  background-color: #fff;
  color: #000;
  border: unset;
  border-radius: 0;
  font-size: 14px;
  padding: 0px 10px;
}
.job-demand .find-job-btn .find-more-jobs-btn {
  border-radius: 10px;
  background: #1B375C;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border: none;
  outline: none;
}

.impaneled-companies {
  padding: 20px 0;
}
.impaneled-companies .slick-list {
  padding: 15px;
}
.impaneled-companies .slick-next:before,
.impaneled-companies .slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #d1d1d1;
}
.impaneled-companies h1 {
  color: #1B375C;
  text-align: center;
  font-family: Barlow;
  font-size: 40px;
  font-weight: 700;
}
.impaneled-companies .company-slider {
  width: 250px !important;
  border-radius: 20px;
  background: #FFF;
  padding: 0 10px;
  box-shadow: 5px 5px 13px 0px rgba(224, 224, 224, 0.5);
}
.impaneled-companies .company-slider .company-logo {
  padding: 8px 0;
}
.impaneled-companies .company-slider .textarea {
  padding: 15px 2px;
}
.impaneled-companies .company-slider .textarea p {
  color: var(--grey-grey-2, #818181);
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 5px;
}
.impaneled-companies .company-slider .textarea h3 {
  color: #1D1D1D;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: left;
}

.job-fair {
  padding: 50px 0;
  border-radius: 4px;
  background: #E9F4FE;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(25, 101, 201, 0.1) inset;
}
.job-fair h1 {
  color: #1B375C;
  text-align: center;
  font-family: Barlow;
  font-size: 40px;
  font-weight: 700;
}
.job-fair .software {
  background: #FFF;
  padding: 25px;
  border-radius: 7px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.job-fair .software .intro img {
  width: 65px;
  height: 65px;
}
.job-fair .software .intro h4 {
  color: #141414;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
}
.job-fair .software .intro h5 {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
  margin-right: 8px;
}
.job-fair .software .intro h6 {
  border-radius: 3px;
  background: rgba(43, 133, 255, 0.1);
  color: #2B85FF;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
}
.job-fair .software .location {
  padding: 15px 0;
}
.job-fair .software .location img {
  width: 21px;
  height: 21px;
}
.job-fair .software .location h5 {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
  padding: 0 5px;
}
.job-fair .software .job-time img {
  width: 18px;
  height: 18px;
}
.job-fair .software .job-time h6 {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
  padding: 0 5px;
}
.job-fair .software .job-pac img {
  width: 18px;
  height: 18px;
}
.job-fair .software .job-pac h6 {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
  padding: 0 5px;
}
.job-fair .software .para-text p {
  color: rgba(20, 20, 20, 0.7);
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.testimonial {
  padding: 50px 0;
  background: rgba(241, 242, 244, 0.75);
}
.testimonial h1 {
  color: #1B375C;
  text-align: center;
  font-family: Barlow;
  font-size: 40px;
  font-weight: 700;
}
.testimonial .slick-list .test-card {
  min-height: 315px;
  background: #fff;
  margin: 0 10px;
  padding: 24px;
  border-radius: 7px;
  border-radius: 4px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), 0px 4px 20px 0px rgba(27, 55, 92, 0.2) inset;
}
.testimonial .slick-list .test-card p {
  height: 170px;
  text-align: left;
}
.testimonial .slick-list .test-card .client {
  justify-content: space-between;
}
.testimonial .slick-list .test-card .client img {
  width: 48px;
  height: 48px;
}
.testimonial .slick-list .test-card .client .avatar img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}
.testimonial .slick-list .test-card .client .avatar .client-info {
  padding-top: 5px;
}
.testimonial .slick-list .test-card .client .avatar .client-info h5 {
  color: var(--gray-scale-900, #191F33);
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}
.testimonial .slick-list .test-card .client .avatar .client-info h6 {
  color: var(--gray-scale-500, #767E94);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}/*# sourceMappingURL=homepage.css.map */