.matching-hiring .banner-area {
  background: url(../../../../../public/assets/images/matching-hiring/h-banner.png);
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  height: 620px;
}
.matching-hiring .banner-area .banner-sec-lft {
  position: relative;
}
.matching-hiring .banner-area .banner-sec-lft:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1b375c;
  opacity: 0.9;
  z-index: -1;
}
.matching-hiring .banner-area .banner-sec-lft::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1B375C;
  opacity: 0.9;
  -webkit-clip-path: polygon(0 0, 92% 0, 65% 100%, 0% 100%);
          clip-path: polygon(0 0, 92% 0, 65% 100%, 0% 100%);
}
.matching-hiring .banner-area .banner-sec-lft .banner-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
.matching-hiring .banner-area .banner-sec-lft .banner-text h1 {
  text-align: left;
  color: #FFF;
  font-size: 55px;
  font-weight: 600;
}
.matching-hiring .banner-area .banner-sec-lft .banner-text p {
  text-align: left;
  color: #FFF;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  margin: 30px 0 35px 0;
}
.matching-hiring .banner-area .banner-sec-lft .banner-text a {
  border-radius: 50px;
  background: #0A65CC;
  color: #FFF;
  border: unset;
  text-decoration: none;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 400;
}

.free-job-post {
  background: #F6F6F6;
  position: relative;
  z-index: -999;
}
.free-job-post .head-area h2 {
  color: #1B375C;
  font-size: 48px;
  font-weight: 700;
}
.free-job-post .custm-features h3,
.free-job-post .custm-features2 h3,
.free-job-post .custm-features3 h3,
.free-job-post .custm-features4 h3 {
  text-align: left;
  color: #ffffff;
  font-size: 28x;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.16px;
}
.free-job-post .custm-features h4,
.free-job-post .custm-features2 h4,
.free-job-post .custm-features3 h4,
.free-job-post .custm-features4 h4 {
  text-align: left;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
}
.free-job-post .custm-features {
  background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
  position: relative;
  background-position: 100%;
  background-size: cover;
  height: 360px;
  padding: 40px;
}
.free-job-post .custm-features::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
  background: linear-gradient(181deg, #1B375C 19%, #1B5DB5 94.34%), rgba(0, 0, 0, 0.45);
}
.free-job-post .custm-features2 {
  background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
  position: relative;
  background-position: 100%;
  background-size: cover;
  height: 360px;
  padding: 40px;
}
.free-job-post .custm-features2::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
  background: linear-gradient(154deg, rgba(28, 50, 251, 0.91) 0%, rgba(29, 180, 143, 0.79) 99%), rgba(0, 0, 0, 0.45);
}
.free-job-post .custm-features3 {
  background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
  position: relative;
  background-position: 100%;
  background-size: cover;
  height: 360px;
  padding: 40px;
}
.free-job-post .custm-features3::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
  background: linear-gradient(154deg, rgba(28, 50, 251, 0.91) 0%, rgba(29, 180, 143, 0.79) 99%), rgba(0, 0, 0, 0.45);
}
.free-job-post .custm-features4 {
  background-image: url(../../../../../public/assets/images/matching-hiring/f1.png);
  position: relative;
  background-position: 100%;
  background-size: cover;
  height: 360px;
  padding: 40px;
}
.free-job-post .custm-features4::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
  background: linear-gradient(181deg, #1B375C 19%, #1B5DB5 94.34%), rgba(0, 0, 0, 0.45);
}

.add-budget .head-area h2 {
  color: #1B375C;
  font-size: 48px;
  font-weight: 700;
}
.add-budget .custm-bud-text h4 {
  text-align: left;
  color: #1B375C;
  font-size: 28px;
  font-weight: 700;
  padding: 50px 0 0 0;
}
.add-budget .custm-bud-text p {
  text-align: left;
  color: #1E1E1E;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
  padding: 0 60px 0 0;
}
.add-budget .custm-bud-img img {
  width: 400px;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  border-radius: 25px;
  border: 10px solid #fff;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.search-candidates {
  background: #F6F6F6;
}
.search-candidates .head-area h2 {
  color: #1B375C;
  font-size: 48px;
  font-weight: 700;
}
.search-candidates .form-area {
  width: 535px;
  height: 500px;
  flex-shrink: 0;
  padding: 60px 40px 40px 40px;
  border-radius: 30px;
  background: #1B375C;
  position: relative;
  z-index: 999;
}
.search-candidates .form-area h5 {
  text-align: left;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 20px 0;
  line-height: 25px;
}
.search-candidates .form-area label {
  display: flex;
  color: #ffffff;
}
.search-candidates .form-area input {
  background: #1B375C;
  height: 50px;
}
.search-candidates .form-area input::-moz-placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.search-candidates .form-area input::placeholder {
  color: #F6F6F6;
  padding: 0 0 0 10px;
}
.search-candidates .form-area .login-btn {
  width: 90%;
  padding: 10px 10px;
  border-radius: 32px;
  background: #F0F7FF;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
  color: #1B375C;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
}
.search-candidates .form-img img {
  width: 662px;
  height: 495px;
}/*# sourceMappingURL=hiring.css.map */