@media only screen and (min-width: 768px) and (max-width: 992px) {
  .sidebar-menu-parent .sidebar-menu .sidebar-link {
    height: 340px;
    overflow-y: scroll;
  }
  .sidebar-menu-parent .sidebar-menu .sidebar-unlist li:last-child {
    margin-bottom: 130px;
  }
  .after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add h3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add p {
    color: #fff;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .after-login-sec .main-header .head-sec-rit .head-right-user .user-name-add p {
    color: #fff;
    margin-bottom: 0;
  }
  .after-login-sec .header-second {
    display: block;
  }
  .after-login-sec .header-second .menu-tab {
    margin-right: 0px;
  }
  .after-login-sec .header-second .navbar-ul-custom {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .after-login-sec .modal-body-content {
    padding: 9px 0px;
  }
  .after-login-sec .sidebar-menu-parent .sidebar-menu .sidebar-link {
    height: 340px;
    overflow-y: scroll;
  }
}/*# sourceMappingURL=AfterNavbarResponsive.css.map */