.feature-blog-listing {
    .container {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.50), -4px -4px 20px 0px rgba(27, 55, 92, 0.20) inset;
    }

    .custm-sec-lft {
        h1 {
            text-align: left;
            color: #141414;
            font-family: Barlow;
            font-size: 32px;
            font-weight: 700;
            padding: 0 0 15px 0;
        }

        .new-job-info {
            h5 {
                text-align: left;
                color: #666;
                font-size: 12px;
                font-weight: 400;
            }

            h2 {
                text-align: left;
                color: #222;
                font-size: 18px;
                font-weight: 600;
            }

            ul {
                padding: unset;
                margin-bottom: 0;
                li {
                    list-style: none;
                    color: #777;
                    font-size: 12px;
                    font-weight: 400;

                    img {
                        padding: 0 5px 0 0;
                    }
                }

                li:nth-child(2) {
                    padding: 0 20px;
                }
            }

            h4 {
                text-align: left;
                color: #555;
                font-size: 15px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }

    .custm-sec-rit {
        .recent-post {
            padding: 0 5px;

            h3 {
                color: #1B375C;
                font-family: Barlow;
                font-size: 32px;
                font-weight: 600;
            }

            h4 {
                text-align: left;
                color: #1B375C;
                font-family: Barlow;
                font-size: 22px;
                font-weight: 600;
            }

            .custm-posts {
                .post-detail {
                    .post-text {
                        h4 {
                            text-align: left;
                            color: var(--sale-tage-coloe, #3F509E);
                            font-size: 14px;
                            font-weight: 400;
                        }

                        h5 {
                            text-align: left;
                            color: var(--sub-text-color, #8A8FB9);
                            font-size: 11px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

    }

    .view-btn {
        a {
            padding: 10px 50px;
            text-decoration: none;
            border-radius: 8px;
            border: 1px solid rgba(27, 55, 92, 0.64);
            background: var(--light-background-transparent, rgba(255, 255, 255, 0.00));
            color: #1B375C;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }
    }
}


@media only screen and (min-width:200px) and (max-width:567px) {

    .feature-blog-listing {
        .custm-sec-lft {
            .title {
                h1 {
                    font-size: 25px;
                }
            }

            .new-job-info {
                h2 {
                    font-size: 16px;
                }

                ul {
                    display: block !important;

                    li {
                        margin: 0 0 10px 0;
                    }

                    li:nth-child(2) {
                        padding: unset;
                    }
                }
            }

            .new-job-img {
                padding: unset !important;
            }
        }

        .custm-sec-rit {
            .recent-post {
                h3 {
                    font-size: 25px;
                }
            }
        }

        .view-btn {
            a {
                padding: 4px 22px;
                font-size: 14px;
            }
        }

    }
}
@media only screen and (min-width:568px) and (max-width:992px) {

    .feature-blog-listing {
        .custm-sec-lft {
            .new-job-info {
                ul {
                    display: block !important;

                    li {
                        margin: 0 0 10px 0;
                    }

                    li:nth-child(2) {
                        padding: unset;
                    }
                }
            }
        }

    }
}