.create-an-account-section {
  margin: 50px 0px;
}
.create-an-account-section li {
  list-style: none;
}
.create-an-account-section a {
  text-decoration: none;
}
.create-an-account-section .main-section .second-section {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5), -4px -4px 20px 0px rgba(27, 55, 92, 0.2) inset;
  padding: 10px;
}
.create-an-account-section .main-section .second-section .first-head h2 {
  color: #333;
  text-align: center;
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
}
.create-an-account-section .main-section .second-section .first-head p {
  color: #848484;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.create-an-account-section .main-section .second-section .icon-section ul {
  display: flex;
  /* height: 64px; */
  /* padding: 10px 168.5px 10.884px 181.5px; */
  justify-content: center;
  align-items: center;
  gap: 20.357px;
}
.create-an-account-section .before-form-dv {
  margin: 0px 45px;
}
.create-an-account-section .before-form-dv label {
  color: #000;
  font-size: 16px;
  text-align: left;
  float: left;
}
.create-an-account-section .top-btn {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-an-account-section .form-custom {
  /* width: 500px; */
  padding: 15px 12px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.create-an-account-section .form-custom2 {
  padding: 15px 12px 17px 24px;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
}
.create-an-account-section .form-btn {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: #0A65CC;
  padding: 15px 49px 17px 49px;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}
.create-an-account-section .craete-form {
  padding: 26px;
}
.create-an-account-section .craete-form .verify-icon {
  position: relative;
}
.create-an-account-section .craete-form .verify-icon i {
  position: absolute;
  right: 35px;
  top: 52px;
  color: #7f8082;
}
.create-an-account-section .login-a-tag {
  margin-left: 8px;
}
.create-an-account-section .form-btn2 {
  width: 300px;
  padding: 10px 40px;
  border-radius: 40px;
  background: #1B375C;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}
.create-an-account-section .slash-icon {
  width: auto;
  color: #666;
  position: absolute;
  right: 15px;
  top: 50px;
}
.create-an-account-section .slash-icon .hide-text {
  font-family: "Inter", "Barlow", sans-serif;
  font-weight: 400;
}
.create-an-account-section .modal-btnn {
  border: 1px solid white;
  background: white;
}
.create-an-account-section .height-100 {
  /* height: 72vh */
}
.create-an-account-section .para {
  color: var(--grey-grey-2, #818181);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
}
.create-an-account-section .card {
  border: none;
  width: 496px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-an-account-section .card h6 {
  color: var(--grey-grey-1, #0D0D0D);
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39.4px;
  text-transform: capitalize;
}
.create-an-account-section .inputs input {
  width: 40px;
  height: 40px;
}
.create-an-account-section input[type=number]::-webkit-inner-spin-button,
.create-an-account-section input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.create-an-account-section .card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
}
.create-an-account-section .card-left {
  margin: 45px;
}
.create-an-account-section .modal-body {
  padding: 0px !important;
}
.create-an-account-section .checkbox-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.create-an-account-section .checkbox-dv .checkbox-input {
  margin-right: 5px;
}
.create-an-account-section .by-policy-dv {
  text-align: left;
  margin: 10px 0px;
}
.create-an-account-section .by-policy-dv p a {
  text-decoration: underline;
}
.create-an-account-section .already-account-dv {
  margin-top: 10px;
}
.create-an-account-section .already-account-dv .login-a-tag {
  text-decoration: underline;
}
.create-an-account-section .validate {
  height: 40px;
  border-radius: 40px;
  background-color: #1B375C;
  border: 1px solid #1B375C;
  width: 140px;
}
.create-an-account-section .password-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.create-an-account-section .verified-btn {
  margin-top: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .create-an-account-section .before-form-dv {
    margin: 0px 0px;
  }
  .create-an-account-section .before-form-dv .craete-form {
    padding: 10px;
  }
  .create-an-account-section .before-form-dv .craete-form .form-custom {
    padding: 5px;
  }
  .create-an-account-section .before-form-dv .craete-form .form-custom2 {
    padding: 5px;
  }
  .create-an-account-section .before-form-dv .verify-icon i {
    position: absolute;
    right: 15px;
    top: 42px;
    color: #7f8082;
  }
  .create-an-account-section .before-form-dv .slash-icon {
    width: auto;
    color: #666;
    position: absolute;
    right: 15px;
    top: 42px;
  }
  .create-an-account-section .before-form-dv .form-btn {
    padding: 5px 25px;
    width: 100%;
  }
  .verified-btn {
    margin-top: 0px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .create-an-account-section .main-section .second-section .first-head h2 {
    font-size: 28px;
  }
  .create-an-account-section .main-section .second-section .icon-section ul {
    padding-left: 0px;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .craete-form {
    padding: 10px;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .craete-form .form-custom {
    padding: 5px;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .craete-form .form-custom2 {
    padding: 5px;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .verify-icon i {
    position: absolute;
    right: 15px;
    top: 42px;
    color: #7f8082;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .slash-icon {
    width: auto;
    color: #666;
    position: absolute;
    right: 15px;
    top: 42px;
  }
  .create-an-account-section .main-section .second-section .before-form-dv .form-btn {
    padding: 10px 25px;
    width: 100%;
  }
  .create-an-account-section .before-form-dv {
    margin: 0px 0px;
  }
  .verified-btn {
    margin-top: 0px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }
}/*# sourceMappingURL=CreateAnAccount.css.map */