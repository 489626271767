@media only screen and (min-width: 200px) and (max-width: 567px) {
  .personal-detail .form-area {
    padding: 22px;
  }
  .personal-detail .form-area .custm-btn-sec {
    display: block !important;
  }
  .personal-detail .form-area form input {
    padding: 8px !important;
  }
  .personal-detail .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .personal-detail .form-area form input::placeholder {
    font-size: 14px;
  }
  .personal-detail .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .personal-detail .form-area form select option {
    font-size: 13px;
  }
  .personal-detail .form-area form .exp-input {
    display: block !important;
  }
  .personal-detail .form-area form .verify-btn {
    padding: 6px 28px;
  }
  .personal-detail .form-area form .cus-pass-section span {
    top: 47px;
  }
  .personal-detail .form-area form .cv-upload .upload {
    position: relative;
  }
  .personal-detail .form-area form .cv-upload .upload .cv-upload-input-cus {
    width: 100%;
  }
  .personal-detail .form-area form .cv-upload .upload img {
    width: 30px;
    height: 30px;
    top: 0;
    position: absolute;
    right: 0;
  }
  .personal-detail .form-area form .cv-upload .resume h4 {
    font-size: 16px;
  }
  .personal-detail .form-area form .cv-upload .resume p {
    font-size: 14px;
  }
  .personal-detail .form-area form .cus-verify img {
    display: none;
  }
  .personal-detail .form-area form .profile-edit-btn .edit-btn a {
    padding: 5px 40px;
  }
  .personal-detail .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .personal-detail .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .personal-detail .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .educational-detail .form-area {
    padding: 22px;
  }
  .educational-detail .form-area .edu-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .educational-detail .form-area form input {
    padding: 8px !important;
  }
  .educational-detail .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .educational-detail .form-area form input::placeholder {
    font-size: 14px;
  }
  .educational-detail .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .educational-detail .form-area form select option {
    font-size: 13px;
  }
  .educational-detail .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .educational-detail .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .educational-detail .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .educational-detail .form-area .custm-btn-sec {
    display: block !important;
  }
  .professional-detail .form-area {
    padding: 22px;
  }
  .professional-detail .form-area .edu-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .professional-detail .form-area form input {
    padding: 8px !important;
  }
  .professional-detail .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .professional-detail .form-area form input::placeholder {
    font-size: 14px;
  }
  .professional-detail .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .professional-detail .form-area form select option {
    font-size: 13px;
  }
  .professional-detail .form-area form .work-confo {
    display: block !important;
  }
  .professional-detail .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .professional-detail .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .professional-detail .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .professional-detail .form-area .custm-btn-sec {
    display: block !important;
  }
  .project-detail .form-area {
    padding: 22px;
  }
  .project-detail .form-area .project-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .project-detail .form-area form input {
    padding: 8px !important;
  }
  .project-detail .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .project-detail .form-area form input::placeholder {
    font-size: 14px;
  }
  .project-detail .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .project-detail .form-area form select option {
    font-size: 13px;
  }
  .project-detail .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .project-detail .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .project-detail .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .project-detail .form-area .custm-btn-sec {
    display: block !important;
  }
  .certification .form-area {
    padding: 22px;
  }
  .certification .form-area .project-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .certification .form-area form input {
    padding: 8px !important;
  }
  .certification .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .certification .form-area form input::placeholder {
    font-size: 14px;
  }
  .certification .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .certification .form-area form select option {
    font-size: 13px;
  }
  .certification .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .certification .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .certification .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .certification .form-area .custm-btn-sec {
    display: block !important;
  }
  .social-media .form-area {
    padding: 22px;
  }
  .social-media .form-area .project-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .social-media .form-area form input {
    padding: 8px !important;
  }
  .social-media .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .social-media .form-area form input::placeholder {
    font-size: 14px;
  }
  .social-media .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .social-media .form-area form select option {
    font-size: 13px;
  }
  .social-media .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .social-media .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .social-media .form-area .custm-btn-sec {
    display: block !important;
  }
  .key-skills .form-area {
    padding: 22px;
  }
  .key-skills .form-area .project-title h1 {
    font-size: 20px;
    padding: 10px 0;
  }
  .key-skills .form-area form input {
    padding: 8px !important;
  }
  .key-skills .form-area form input::-moz-placeholder {
    font-size: 14px;
  }
  .key-skills .form-area form input::placeholder {
    font-size: 14px;
  }
  .key-skills .form-area form select {
    padding: 8px !important;
    font-size: 13px;
  }
  .key-skills .form-area form select option {
    font-size: 13px;
  }
  .key-skills .form-area form .custm-botton-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .key-skills .form-area form .skip {
    padding: 8px 35px;
    font-size: 16px;
  }
  .key-skills .form-area form .next {
    padding: 8px 35px;
    font-size: 16px;
  }
  .key-skills .form-area .custm-btn-sec {
    display: block !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 567px) and (min-width: 200px) and (max-width: 767px) {
  .form-area .personal-detail .cv-upload .resume h4 {
    font-size: 14px !important;
  }
  .form-area .personal-detail .cv-upload .resume p {
    font-size: 14px !important;
  }
}/*# sourceMappingURL=cvresponsive.css.map */