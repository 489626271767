@media only screen and (min-width: 568px) and (max-width: 992px) {
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .rec-header-banner .banner-text {
    transform: translate(-50%, -33%);
  }
  .rec-header-banner .banner-text h1 {
    font-size: 30px;
  }
  .rec-header-banner .banner-text button {
    padding: 10px 15px;
    font-size: 13px;
  }
  .rec-bannerafter-header .achievements-lft h1 {
    font-size: 30px;
    text-align: center;
  }
  .rec-bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 22px;
  }
  .job-demand .job-box2 {
    margin-bottom: 10px;
  }
  .work-location h1 {
    font-size: 30px;
  }
  .job-opening h1 {
    font-size: 30px;
  }
  .job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 15px;
  }
  .benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .job-demand h1 {
    font-size: 30px;
  }
  .impaneled-companies h1 {
    font-size: 30px;
  }
  .job-fair h1 {
    font-size: 30px;
  }
  .testimonial h1 {
    font-size: 30px;
  }
  .heading-txt {
    margin: 10px 0 !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 567px) {
  .common-header-sec .main-header .row > .col-lg-6 {
    justify-content: center;
  }
  .common-header-sec .main-header .head-sec-lft p {
    font-size: 13px;
  }
  .common-header-sec .main-header .head-sec-lft {
    margin-bottom: 15px;
  }
  .common-header-sec .header-second nav {
    z-index: 1;
  }
  .rec-header-banner .banner-text {
    transform: translate(-50%, -40%);
  }
  .rec-header-banner .banner-text h1 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .rec-header-banner .banner-text button {
    width: 125px;
    height: 35px;
    font-size: 10px;
    padding: 0;
  }
  .rec-header-banner .banner-text .footer-parent-sec .ft-rt-dv .inner-img-dv {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .rec-bannerafter-header {
    padding: 30px;
  }
  .rec-bannerafter-header .achievements-lft h1 {
    font-size: 26px;
    line-height: 38px;
    text-align: center;
  }
  .rec-bannerafter-header .achievements-lft p {
    text-align: center;
  }
  .achievements-rit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
  }
  .achievements-rit .counts {
    justify-content: flex-start;
  }
  .achievements-rit .counter-txt h2 {
    font-size: 22px;
  }
  .rec-work-location h1 {
    font-size: 30px;
  }
  .rec-work-location .heading-txt {
    margin: 0px 0 !important;
  }
  .rec-job-opening h1 {
    font-size: 30px;
  }
  .rec-job-opening .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 15px;
    margin: 0;
  }
  .rec-benifits .key-benifits {
    padding: 20px;
  }
  .rec-benifits .key-benifits .heading-txt h1 {
    font-size: 30px;
  }
  .rec-job-demand h1 {
    font-size: 30px;
  }
  .rec-job-demand .job-box h4 {
    font-size: 18px;
  }
  .rec-job-demand .job-box .company button {
    font-size: 14px;
  }
  .rec-job-demand .job-box2 {
    margin-bottom: 20px;
  }
  .rec-job-demand .job-box2 h4 {
    font-size: 18px;
  }
  .rec-job-demand .job-box2 .company button {
    font-size: 14px;
  }
  .rec-impaneled-companies h1 {
    font-size: 30px;
  }
  .rec-job-fair {
    padding: unset;
  }
  .rec-job-fair h1 {
    font-size: 30px;
  }
  .rec-job-fair .heading-txt p {
    text-align: center;
  }
  .software {
    margin-bottom: 15px;
  }
  .software .intro h4 {
    font-size: 16px;
  }
  .rec-testimonial h1 {
    font-size: 30px;
  }
}/*# sourceMappingURL=homeresponsive.css.map */